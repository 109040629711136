import { Colors, base_font } from '@styles/base/styled';

const TestimonialStyle = {
  BgColors: {
    card: Colors.gray2,
    cardHighlight: Colors.black,
    thumbnail: Colors.white,
  },
  Colors: {
    quote: Colors.black,
    quoteHighlight: Colors.white,
    cardHighlight: Colors.white,
    cardShadow: Colors.shadowSharp,
  },
  FontSizes: {
    quote: (base_font * 2.25).toFixed(1) + 'rem',
  },
};

export default TestimonialStyle;
