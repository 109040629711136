export const US_CITIES = [
  {
    slug: 'phoenix-arizona',
    place_name: 'Phoenix Arizona',
    code: 'AZ',
    city: 'Phoenix',
  },
  {
    slug: 'scottsdale-arizona',
    place_name: 'Scottsdale Arizona',
    code: 'AZ',
    city: 'Scottsdale',
  },
  {
    slug: 'tucson-arizona',
    place_name: 'Tucson Arizona',
    code: 'AZ',
    city: 'Tucson',
  },
  {
    slug: 'los-angeles-california',
    place_name: 'Los Angeles California',
    code: 'CA',
    city: 'Los Angeles',
  },
  {
    slug: 'oakland-california',
    place_name: 'Oakland California',
    code: 'CA',
    city: 'Oakland',
  },
  {
    slug: 'sacramento-california',
    place_name: 'Sacramento California',
    code: 'CA',
    city: 'Sacramento',
  },
  {
    slug: 'san-francisco-california',
    place_name: 'San Francisco California',
    code: 'CA',
    city: 'San Francisco',
  },
  {
    slug: 'san-jose-california',
    place_name: 'San Jose California',
    code: 'CA',
    city: 'San Jose',
  },
  {
    slug: 'aurora-colorado',
    place_name: 'Aurora Colorado',
    code: 'CO',
    city: 'Aurora',
  },
  {
    slug: 'colorado-springs-colorado',
    place_name: 'Colorado Springs Colorado',
    code: 'CO',
    city: 'Colorado Springs',
  },
  {
    slug: 'denver-colorado',
    place_name: 'Denver Colorado',
    code: 'CO',
    city: 'Denver',
  },
  {
    slug: 'hartford-connecticut',
    place_name: 'Hartford Connecticut',
    code: 'CT',
    city: 'Hartford',
  },
  {
    slug: 'new-haven-connecticut',
    place_name: 'New Haven Connecticut',
    code: 'CT',
    city: 'New Haven',
  },
  {
    slug: 'stamford-connecticut',
    place_name: 'Stamford Connecticut',
    code: 'CT',
    city: 'Stamford',
  },
  {
    slug: 'miami-florida',
    place_name: 'Miami Florida',
    code: 'FL',
    city: 'Miami',
  },
  {
    slug: 'orlando-florida',
    place_name: 'Orlando Florida',
    code: 'FL',
    city: 'Orlando',
  },
  {
    slug: 'tampa-florida',
    place_name: 'Tampa, Florida',
    code: 'FL',
    city: 'Tampa',
  },
  {
    slug: 'atlanta-georgia',
    place_name: 'Atlanta, Georgia',
    code: 'GA',
    city: 'Atlanta',
  },
  {
    slug: 'chicago-illinois',
    place_name: 'Chicago, Illinois',
    code: 'IL',
    city: 'Chicago',
  },
  {
    slug: 'indianapolis-indiana',
    place_name: 'Indianapolis, Indiana',
    code: 'IN',
    city: 'Indianapolis',
  },
  {
    slug: 'new-orleans-louisiana',
    place_name: 'New Orleans, Louisiana',
    code: 'LA',
    city: 'New Orleans',
  },
  {
    slug: 'baltimore-maryland',
    place_name: 'Baltimore, Maryland',
    code: 'MD',
    city: 'Baltimore',
  },
  {
    slug: 'boston-massachusetts',
    place_name: 'Boston, Massachusetts',
    code: 'MA',
    city: 'Boston',
  },
  {
    slug: 'cambridge-massachusetts',
    place_name: 'Cambridge, Massachusetts',
    code: 'MA',
    city: 'Cambridge',
  },
  {
    slug: 'detroit-michigan',
    place_name: 'Detroit, Michigan',
    code: 'MI',
    city: 'Detroit',
  },
  {
    slug: 'minneapolis-minnesota',
    place_name: 'Minneapolis, Minnesota',
    code: 'MN',
    city: 'Minneapolis',
  },
  {
    slug: 'saint-louis-missouri',
    place_name: 'Saint Louis, Missouri',
    code: 'MO',
    city: 'Saint Louis',
  },
  {
    slug: 'las-vegas-nevada',
    place_name: 'Las Vegas, Nevada',
    code: 'NV',
    city: 'Las Vegas',
  },
  {
    slug: 'newark-new-jersey',
    place_name: 'Newark, New Jersey',
    code: 'NJ',
    city: 'Newark',
  },
  {
    slug: 'brooklyn-new-york',
    place_name: 'Brooklyn, New York',
    code: 'NY',
    city: 'Brooklyn',
  },
  {
    slug: 'new-york-new-york',
    place_name: 'New York, New York',
    code: 'NY',
    city: 'New York',
  },
  {
    slug: 'charlotte-north-carolina',
    place_name: 'Charlotte, North Carolina',
    code: 'NC',
    city: 'Charlotte',
  },
  {
    slug: 'cincinnati-ohio',
    place_name: 'Cincinnati, Ohio',
    code: 'OH',
    city: 'Cincinnati',
  },
  {
    slug: 'columbus-ohio',
    place_name: 'Columbus, Ohio',
    code: 'OH',
    city: 'Columbus',
  },
  {
    slug: 'tulsa-oklahoma',
    place_name: 'Tulsa, Oklahoma',
    code: 'OK',
    city: 'Tulsa',
  },
  {
    slug: 'portland-oregon',
    place_name: 'Portland, Oregon',
    code: 'OR',
    city: 'Portland',
  },
  {
    slug: 'philadelphia-pennsylvania',
    place_name: 'Philadelphia, Pennsylvania',
    code: 'PA',
    city: 'Philadelphia',
  },
  {
    slug: 'pittsburgh-pennsylvania',
    place_name: 'Pittsburgh, Pennsylvania',
    code: 'PA',
    city: 'Pittsburgh',
  },
  {
    slug: 'nashville-tennessee',
    place_name: 'Nashville, Tennessee',
    code: 'TN',
    city: 'Nashville',
  },
  {
    slug: 'austin-texas',
    place_name: 'Austin, Texas',
    code: 'TX',
    city: 'Austin',
  },
  {
    slug: 'dallas-texas',
    place_name: 'Dallas, Texas',
    code: 'TX',
    city: 'Dallas',
  },
  {
    slug: 'houston-texas',
    place_name: 'Houston, Texas',
    code: 'TX',
    city: 'Houston',
  },
  {
    slug: 'san-antonio-texas',
    place_name: 'San Antonio, Texas',
    code: 'TX',
    city: 'San Antonio',
  },
  {
    slug: 'salt-lake-city-utah',
    place_name: 'Salt Lake City, Utah',
    code: 'UT',
    city: 'Salt Lake City',
  },
  {
    slug: 'richmond-virginia',
    place_name: 'Richmond, Virginia',
    code: 'VA',
    city: 'Richmond',
  },
  {
    slug: 'seattle-washington',
    place_name: 'Seattle, Washington',
    code: 'WA',
    city: 'Seattle',
  },
  {
    slug: 'madison-wisconsin',
    place_name: 'Madison, Wisconsin',
    code: 'WI',
    city: 'Madison',
  },
  {
    slug: 'milwaukee-wisconsin',
    place_name: 'Milwaukee, Wisconsin',
    code: 'WI',
    city: 'Milwaukee',
  },
  {
    slug: 'birmingham-alabama',
    place_name: 'Birmingham, Alabama',
    code: 'AL',
    city: 'Birmingham',
  },
  {
    slug: 'mobile-alabama',
    place_name: 'Mobile, Alabama',
    code: 'AL',
    city: 'Mobile',
  },
  {
    slug: 'montgomery-alabama',
    place_name: 'Montgomery, Alabama',
    code: 'AL',
    city: 'Montgomery',
  },
  {
    slug: 'little-rock-arkansas',
    place_name: 'Little Rock, Arkansas',
    code: 'AR',
    city: 'Little Rock',
  },
  {
    slug: 'glendale-arizona',
    place_name: 'Glendale, Arizona',
    code: 'AZ',
    city: 'Glendale',
  },
  {
    slug: 'tempe-arizona',
    place_name: 'Tempe, Arizona',
    code: 'AZ',
    city: 'Tempe',
  },
  {
    slug: 'chandler-arizona',
    place_name: 'Chandler, Arizona',
    code: 'AZ',
    city: 'Chandler',
  },
  {
    slug: 'mesa-arizona',
    place_name: 'Mesa, Arizona',
    code: 'AZ',
    city: 'Mesa',
  },
  {
    slug: 'anaheim-california',
    place_name: 'Anaheim, California',
    code: 'CA',
    city: 'Anaheim',
  },
  {
    slug: 'bakersfield-california',
    place_name: 'Bakersfield, California',
    code: 'CA',
    city: 'Bakersfield',
  },
  {
    slug: 'fresno-california',
    place_name: 'Fresno, California',
    code: 'CA',
    city: 'Fresno',
  },
  {
    slug: 'irvine-california',
    place_name: 'Irvine, California',
    code: 'CA',
    city: 'Irvine',
  },
  {
    slug: 'long-beach-california',
    place_name: 'Long Beach, California',
    code: 'CA',
    city: 'Long Beach',
  },
  {
    slug: 'riverside-california',
    place_name: 'Riverside, California',
    code: 'CA',
    city: 'Riverside',
  },
  {
    slug: 'san-diego-california',
    place_name: 'San Diego, California',
    code: 'CA',
    city: 'San Diego',
  },
  {
    slug: 'stockton-california',
    place_name: 'Stockton, California',
    code: 'CA',
    city: 'Stockton',
  },
  {
    slug: 'fort-collins-colorado',
    place_name: 'Fort Collins, Colorado',
    code: 'CO',
    city: 'Fort Collins',
  },
  {
    slug: 'bridgeport-connecticut',
    place_name: 'Bridgeport, Connecticut',
    code: 'CT',
    city: 'Bridgeport',
  },
  {
    slug: 'fort-lauderdale-florida',
    place_name: 'Fort Lauderdale, Florida',
    code: 'FL',
    city: 'Fort Lauderdale',
  },
  {
    slug: 'jacksonville-florida',
    place_name: 'Jacksonville, Florida',
    code: 'FL',
    city: 'Jacksonville',
  },
  {
    slug: 'st-petersburg-florida',
    place_name: 'St. Petersburg, Florida',
    code: 'FL',
    city: 'St. Petersburg',
  },
  {
    slug: 'honolulu-hawaii',
    place_name: 'Honolulu, Hawaii',
    code: 'HI',
    city: 'Honolulu',
  },
  {
    slug: 'des-moines-iowa',
    place_name: 'Des Moines, Iowa',
    code: 'IA',
    city: 'Des Moines',
  },
  {
    slug: 'boise-idaho',
    place_name: 'Boise, Idaho',
    code: 'ID',
    city: 'Boise',
  },
  {
    slug: 'fort-wayne-indiana',
    place_name: 'Fort Wayne, Indiana',
    code: 'IN',
    city: 'Fort Wayne',
  },
  {
    slug: 'wichita-kansas',
    place_name: 'Wichita, Kansas',
    code: 'KS',
    city: 'Wichita',
  },
  {
    slug: 'lexington-kentucky',
    place_name: 'Lexington, Kentucky',
    code: 'KY',
    city: 'Lexington',
  },
  {
    slug: 'louisville-kentucky',
    place_name: 'Louisville, Kentucky',
    code: 'KY',
    city: 'Louisville',
  },
  {
    slug: 'baton-rouge-louisiana',
    place_name: 'Baton Rouge, Louisiana',
    code: 'LA',
    city: 'Baton Rouge',
  },
  {
    slug: 'shreveport-louisiana',
    place_name: 'Shreveport, Louisiana',
    code: 'LA',
    city: 'Shreveport',
  },
  {
    slug: 'springfield-massachusetts',
    place_name: 'Springfield, Massachusetts',
    code: 'MA',
    city: 'Springfield',
  },
  {
    slug: 'worcester-massachusetts',
    place_name: 'Worcester, Massachusetts',
    code: 'MA',
    city: 'Worcester',
  },
  {
    slug: 'grand-rapids-michigan',
    place_name: 'Grand Rapids, Michigan',
    code: 'MI',
    city: 'Grand Rapids',
  },
  {
    slug: 'st-paul-minnesota',
    place_name: 'St. Paul, Minnesota',
    code: 'MN',
    city: 'St. Paul',
  },
  {
    slug: 'kansas-city-missouri',
    place_name: 'Kansas City, Missouri',
    code: 'MO',
    city: 'Kansas City',
  },
  {
    slug: 'springfield-missouri',
    place_name: 'Springfield, Missouri',
    code: 'MO',
    city: 'Springfield',
  },
  {
    slug: 'jackson-mississippi',
    place_name: 'Jackson, Mississippi',
    code: 'MS',
    city: 'Jackson',
  },
  {
    slug: 'cary-north-carolina',
    place_name: 'Cary, North Carolina',
    code: 'NC',
    city: 'Cary',
  },
  {
    slug: 'durham-north-carolina',
    place_name: 'Durham, North Carolina',
    code: 'NC',
    city: 'Durham',
  },
  {
    slug: 'greensboro-north-carolina',
    place_name: 'Greensboro, North Carolina',
    code: 'NC',
    city: 'Greensboro',
  },
  {
    slug: 'raleigh-north-carolina',
    place_name: 'Raleigh, North Carolina',
    code: 'NC',
    city: 'Raleigh',
  },
  {
    slug: 'winston-salem-north-carolina',
    place_name: 'Winston-Salem, North Carolina',
    code: 'NC',
    city: 'Winston-Salem',
  },
  {
    slug: 'lincoln-nebraska',
    place_name: 'Lincoln, Nebraska',
    code: 'NE',
    city: 'Lincoln',
  },
  {
    slug: 'omaha-nebraska',
    place_name: 'Omaha, Nebraska',
    code: 'NE',
    city: 'Omaha',
  },
  {
    slug: 'jersey-city-new-jersey',
    place_name: 'Jersey City, New Jersey',
    code: 'NJ',
    city: 'Jersey City',
  },
  {
    slug: 'albuquerque-new-mexico',
    place_name: 'Albuquerque, New Mexico',
    code: 'NM',
    city: 'Albuquerque',
  },
  {
    slug: 'henderson-nevada',
    place_name: 'Henderson, Nevada',
    code: 'NV',
    city: 'Henderson',
  },
  {
    slug: 'reno-nevada',
    place_name: 'Reno, Nevada',
    code: 'NV',
    city: 'Reno',
  },
  {
    slug: 'bronx-new-york',
    place_name: 'Bronx, New York',
    code: 'NY',
    city: 'Bronx',
  },
  {
    slug: 'buffalo-new-york',
    place_name: 'Buffalo, New York',
    code: 'NY',
    city: 'Buffalo',
  },
  {
    slug: 'manhattan-new-york',
    place_name: 'Manhattan, New York',
    code: 'NY',
    city: 'Manhattan',
  },
  {
    slug: 'queens-new-york',
    place_name: 'Queens, New York',
    code: 'NY',
    city: 'Queens',
  },
  {
    slug: 'rochester-new-york',
    place_name: 'Rochester, New York',
    code: 'NY',
    city: 'Rochester',
  },
  {
    slug: 'staten-island-new-york',
    place_name: 'Staten Island, New York',
    code: 'NY',
    city: 'Staten Island',
  },
  {
    slug: 'akron-ohio',
    place_name: 'Akron, Ohio',
    code: 'OH',
    city: 'Akron',
  },
  {
    slug: 'cleveland-ohio',
    place_name: 'Cleveland, Ohio',
    code: 'OH',
    city: 'Cleveland',
  },
  {
    slug: 'toledo-ohio',
    place_name: 'Toledo, Ohio',
    code: 'OH',
    city: 'Toledo',
  },
  {
    slug: 'oklahoma-city-oklahoma',
    place_name: 'Oklahoma City, Oklahoma',
    code: 'OK',
    city: 'Oklahoma City',
  },
  {
    slug: 'eugene-oregon',
    place_name: 'Eugene, Oregon',
    code: 'OR',
    city: 'Eugene',
  },
  {
    slug: 'salem-oregon',
    place_name: 'Salem, Oregon',
    code: 'OR',
    city: 'Salem',
  },
  {
    slug: 'providence-rhode-island',
    place_name: 'Providence, Rhode Island',
    code: 'RI',
    city: 'Providence',
  },
  {
    slug: 'sioux-falls-south-dakota',
    place_name: 'Sioux Falls, South Dakota',
    code: 'SD',
    city: 'Sioux Falls',
  },
  {
    slug: 'chattanooga-tennessee',
    place_name: 'Chattanooga, Tennessee',
    code: 'TN',
    city: 'Chattanooga',
  },
  {
    slug: 'knoxville-tennessee',
    place_name: 'Knoxville, Tennessee',
    code: 'TN',
    city: 'Knoxville',
  },
  {
    slug: 'memphis-tennessee',
    place_name: 'Memphis, Tennessee',
    code: 'TN',
    city: 'Memphis',
  },
  {
    slug: 'arlington-texas',
    place_name: 'Arlington, Texas',
    code: 'TX',
    city: 'Arlington',
  },
  {
    slug: 'brownsville-texas',
    place_name: 'Brownsville, Texas',
    code: 'TX',
    city: 'Brownsville',
  },
  {
    slug: 'corpus-christi-texas',
    place_name: 'Corpus Christi, Texas',
    code: 'TX',
    city: 'Corpus Christi',
  },
  {
    slug: 'el-paso-texas',
    place_name: 'El Paso, Texas',
    code: 'TX',
    city: 'El Paso',
  },
  {
    slug: 'fort-worth-texas',
    place_name: 'Fort Worth, Texas',
    code: 'TX',
    city: 'Fort Worth',
  },
  {
    slug: 'laredo-texas',
    place_name: 'Laredo, Texas',
    code: 'TX',
    city: 'Laredo',
  },
  {
    slug: 'lubbock-texas',
    place_name: 'Lubbock, Texas',
    code: 'TX',
    city: 'Lubbock',
  },
  {
    slug: 'waco-texas',
    place_name: 'Waco, Texas',
    code: 'TX',
    city: 'Waco',
  },
  {
    slug: 'alexandria-virginia',
    place_name: 'Alexandria, Virginia',
    code: 'VA',
    city: 'Alexandria',
  },
  {
    slug: 'arlington-virginia',
    place_name: 'Arlington, Virginia',
    code: 'VA',
    city: 'Arlington',
  },
  {
    slug: 'norfolk-virginia',
    place_name: 'Norfolk, Virginia',
    code: 'VA',
    city: 'Norfolk',
  },
  {
    slug: 'virginia-beach-virginia',
    place_name: 'Virginia Beach, Virginia',
    code: 'VA',
    city: 'Virginia Beach',
  },
  {
    slug: 'spokane-washington',
    place_name: 'Spokane ,Washington',
    code: 'WA',
    city: 'Spokane',
  },
  {
    slug: 'tacoma-washington',
    place_name: 'Tacoma, Washington',
    code: 'WA',
    city: 'Tacoma',
  },
];
export const US_STATES: Record<string, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};
