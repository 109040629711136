import { Colors, base_font } from '@styles/graphite/styled';

const ExitPopUpStyle = {
  BgColors: {
    popUpColor: Colors.white,
    listItemsBg: Colors.shadowLight,
  },

  Colors: {
    headTitleColor: Colors.blue1,
    topReasons: Colors.azure,
    mainHead: Colors.black,
    textWarning: Colors.azure,
    whyTextColor: Colors.blue1,
    reasonsItemText: Colors.black,
    reasonsItemNumber: Colors.azure,
  },

  FontSizes: {
    reasonsItemText: (base_font * 1).toFixed(1) + 'rem',
    reasonsItemNumber: (base_font * 3.5).toFixed(1) + 'rem',
    whyText: (base_font * 1.25).toFixed(1) + 'rem',
    whyTextIcon: (base_font * 0.75).toFixed(1) + 'rem',
    mainHeadText: (base_font * 1.5).toFixed(1) + 'rem',
    headTitleText: (base_font * 1.4).toFixed(1) + 'rem',
  },
};

export default ExitPopUpStyle;
