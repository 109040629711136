import { Colors, base_font } from '@styles/base/styled';

const SuccessStoriesStyle = {
  Colors: {
    qoute: Colors.black,
    testiLine: Colors.gray2,
    dateRow: Colors.gray2,
  },
  FontSizes: {
    swiperWrap: base_font * 0.875 + 'rem',
    qoute: base_font * 0.875 + 'rem',
  },
};

export default SuccessStoriesStyle;
