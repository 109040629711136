import { Colors } from '@styles/sapphire/styled';

const ToasterSectionStyle = {
  BgColors: {
    info: Colors.blue5,
    bgalert: Colors.red2,
    success: Colors.green2,
    tips: Colors.blue4,
  },
  BdrColors: {
    info: Colors.blue6,
    bdralert: Colors.red3,
    success: Colors.green4,
    tips: Colors.blue2,
  },
  Colors: {
    info: Colors.blue13,
    icon: Colors.blue1,
    coloralert: Colors.red6,
    coloralert1: Colors.red4,
    success: Colors.green3,
    tips: Colors.black,
  },
};

export default ToasterSectionStyle;
