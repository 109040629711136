import { Colors } from '@styles/sapphire/styled';

const FormRadioStyle = {
  Colors: {
    textareaDesc: Colors.gray6,
  },
  BgColors: {
    radioBg: Colors.white,
    radioBgChecked: Colors.blue14,
  },
  BdrColors: {
    inputWrap: Colors.shadowSharp,
    textarea: Colors.gray7,
    radioBgChecked: Colors.blue14,
    radioBgFocus: Colors.shadowyBlack5,
  },
};

export default FormRadioStyle;
