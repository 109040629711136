import { Colors, FontFamily, Icons, base_font } from '@styles/base/styled';

const OverviewStyle = {
  FontSizes: {
    toggle: base_font + 'rem',
    arrowBtn: base_font + 'rem',
    arrowiconfs: (base_font * 0.8125).toFixed(1) + 'rem',
    quoteiconfs: base_font * 2 + 'rem',
    checkouticon: (base_font * 0.75).toFixed(1) + 'rem',
    tablink: (base_font * 1.1875).toFixed(1) + 'rem',
  },
  BgColor: {
    divisionlinkbg: Colors.black,
  },
  Colors: {
    colordark: Colors.black,
    toggle: Colors.black,
    quoteicon: Colors.black,
    authname: Colors.gray2,
    bordercolor: Colors.gray2,
    bordercolor2: Colors.gray1,
    mutedtext: Colors.gray2,
    arrowBtn: Colors.gray2,
    arrowBtnHover: Colors.black,
    storyAuthorShadow: Colors.shadowSharp,
  },
  FontFamily: {
    iconfontfamily: FontFamily.fontAwesome,
  },
  Icons: {
    chevronrighticon: Icons.chevronright,
  },
  FontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
  },
};

export default OverviewStyle;
