import {
  getQSValue,
  localStorageGetItem,
  localStorageSetItem,
  readCookie,
} from '@components/common';
import { _CONSTANTS } from '@components/common/constant';
import { genericClickEvent } from '@components/common/event-tracker';
import {
  BannerCloseBtn,
  CookieBannerWrap,
  DescriptionText,
} from '@components/common/styled';
import { track } from '@utils/eventTrack';
import { getLocation } from 'API/ApiHelpers';
import { isPortalContent, isPPPageLink } from 'helper';
import { useEffect, useState } from 'react';

type Props = {
  closeCookieBanner?: any;
};

const CookieBanner = (props: Props) => {
  const [isCCPA, setCCPA] = useState(false);
  const [cookiebanner, setCookieBanner] = useState(true);

  const sendBannerEvent = () => {
    const apiActionURL = '/eb/api/v1/gdpr/visitors/';
    let isBannerEventSend = window
      ? localStorageGetItem('isBannerEventSend')
      : null;
    if (isBannerEventSend != 'true' && apiActionURL) {
      var intervalsendBanner = setInterval(() => {
        let visitorInfo = getVisitorData();
        if (visitorInfo.visitorID != '00000000-0000-0000-0000-000000000000') {
          clearInterval(intervalsendBanner);
          let actionURL =
            window.location.origin +
            apiActionURL +
            visitorInfo.visitorID +
            '/action?userId=' +
            visitorInfo.userId;
          let actionBody = {
            ActionName: 'Banner Displayed',
            ActionType: 'CCPA',
          };
          fetch(actionURL, {
            method: 'POST',
            body: JSON.stringify(actionBody),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          window.localStorage.setItem('isBannerEventSend', 'true');
        }
      }, 50);
    }
  };

  const getVisitorData = () => {
    let visitorInfo = {
      userId: '00000000-0000-0000-0000-000000000000',
      visitorID: '00000000-0000-0000-0000-000000000000',
    };
    visitorInfo.visitorID =
      readCookie('vstr') ||
      readCookie('spvstr') ||
      readCookie('_snt') ||
      readCookie('_snt_fr') ||
      readCookie('_snt_it') ||
      readCookie('_snt_de') ||
      readCookie('_snt_es') ||
      readCookie('_hpt') ||
      readCookie('_hpsd') ||
      '00000000-0000-0000-0000-000000000000';
    if (visitorInfo.visitorID != '00000000-0000-0000-0000-000000000000') {
      let userStatusCookie = readCookie('UserStatus');
      let userStatus = userStatusCookie ? JSON.parse(userStatusCookie) : null;
      visitorInfo.userId =
        userStatus && userStatus.User.UserId
          ? userStatus.User.UserId
          : '00000000-0000-0000-0000-000000000000';
    }
    return visitorInfo;
  };

  const setCCPABanner = (customcountry: any = '') => {
    var isGPC = false;
    if (
      window &&
      window.navigator &&
      (window.navigator as any).globalPrivacyControl == true
    ) {
      isGPC = true;
    }
    if (!isGPC && customcountry && customcountry.toUpperCase() === 'US') {
      setCCPA(true);
      let props = {
        timestamp: new Date(),
        action: 'shown',
      };
      track('CCPA cookie banner', props);
      props = {
        timestamp: new Date(),
        action: 'CCPA/GDPR',
      };
      track('Banner Displayed', props);
      sendBannerEvent();
    }
  };
  const closeBanner = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    localStorageSetItem(_CONSTANTS.CCPA_STATUS, 'true');
    window.document.cookie = `show_cookie_consent_banner=false; expires=${expirationDate.toUTCString()}`;
    setCCPA(false);
    props.closeCookieBanner();
    let eventProps = {
      timestamp: new Date(),
      action: 'closed',
    };
    track('CCPA cookie banner', eventProps);
  };
  const handleLearnMore = (url: string) => {
    closeBanner();
    window.open(url, '_blank');
  };
  useEffect(() => {
    if (!localStorageGetItem(_CONSTANTS.CCPA_STATUS)) {
      let customcountry =
        getQSValue(_CONSTANTS.CCPA_STATUS) ||
        getQSValue(_CONSTANTS.TEST_COUNTRY);
      if (customcountry) {
        setCCPABanner(customcountry);
        track('identify', { countryCode: customcountry });
      } else {
        getLocation().then((response) => {
          if (response?.data?.countryCode) {
            track('identify', { countryCode: response?.data?.countryCode });
          }
          setCCPABanner(response?.data?.countryCode || '');
        });
      }
    }
    if (
      readCookie('show_cookie_consent_banner') !== '' &&
      readCookie('show_cookie_consent_banner') === 'false'
    ) {
      setCookieBanner(false);
    }
  }, []);
  return (
    <>
      {isCCPA && cookiebanner && (
        <CookieBannerWrap id="cookie-banner" className="show-content">
          <DescriptionText className="show-content">
            {isPortalContent} uses cookies as well as our third-party
            affiliates. When you use our website, you understand that we collect
            personal data to improve your experience.{' '}
            <a
              id="learn-more"
              onClick={() => {
                handleLearnMore(isPPPageLink);
              }}
            >
              Learn more
            </a>
          </DescriptionText>
          <div
            onClick={() =>
              genericClickEvent('cookie banner', {
                action: 'clicked',
                'click option': 'accept',
              })
            }
          >
            <BannerCloseBtn
              id="cookie-close-btn"
              className="fas fa-xmark show-content"
              onClick={closeBanner}
            />
          </div>
        </CookieBannerWrap>
      )}
    </>
  );
};

export default CookieBanner;
