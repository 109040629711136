import { Colors } from '@styles/graphite/styled';

const FormCheckStyle = {
  Colors: {
    label: Colors.black,
  },
  BdrColors: {
    input: Colors.gray2,
    checkboxShadow: Colors.shadowyBlack5,
  },
};

export default FormCheckStyle;
