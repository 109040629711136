import {
  Colors,
  FontFamily,
  FontWeight,
  Icons,
  base_font,
} from '@styles/sapphire/styled';

const toolTipStyle = {
  BgColors: {
    secWrap: Colors.white,
    jobBtnWrap: Colors.gray26,
    remoteWrokTipBg: Colors.blue4,
    popupBg: Colors.white,
    saveLinkHover: Colors.gray8,
    saveLinkHoverBg: Colors.blue2,
    saveLinkHoverDropBg: Colors.white,
    infoIconText: Colors.black8,
  },
  BdrColors: {
    secWrap: Colors.gray2,
    jobBtnWrap: Colors.gray1,
    remoteTipBrdrBefore: Colors.blue2,
    remoteTipBrdrAfter: Colors.blue4,
    remoteWrokTipBrdr: Colors.blue2,
    popupBrdr: Colors.shadowyBlack6,
  },

  Colors: {
    secWrap: Colors.black1,
    iconWrap: Colors.orange2,
    infoIcon: Colors.blue7,
    count: Colors.blue1,
    tipsTitle: Colors.black1,
    tipsText: Colors.blue2,
    jobBtnTxt: Colors.black4,
    filterShadow: Colors.shadowyBlack,
    checkWrapInput: Colors.shadowSharp,
    saveLinkHover: Colors.blue1,
    saveSearchDrop: Colors.blue2,
    innerWrap: Colors.blue1,
    infoIconText: Colors.white,
  },
  FontSizes: {
    infoIcon: (base_font * 0.875).toFixed(1) + 'rem',
    tipsText: (base_font * 0.875).toFixed(1) + 'rem',
    iconWrap: (base_font * 1.5).toFixed(1) + 'rem',
    filterBtn: (base_font * 0.75).toFixed(1) + 'rem',
    resultDeskHead: (base_font * 1.5).toFixed(1) + 'rem',
    resultHead: base_font + 'rem',
    savedSearchMenu: base_font + 'rem',
    infoIconText: (base_font * 0.75).toFixed(1) + 'rem',
  },
  FontWeight: {
    closeIcon: FontWeight.normal,
    tipsTitle: FontWeight.semibold,
    tipsText: FontWeight.medium,
  },
  FontFamily: {
    closeIcon: FontFamily.fontAwesome,
  },
  Icons: {
    arrow: Icons.pluscircle,
  },
};

export default toolTipStyle;
