import { Colors, FontFamily, Icons, base_font } from '@styles/base/styled';

const TopCatgStyle = {
  Colors: {
    plusIcon: Colors.black,
    moreCatInfo: Colors.black,
    btnEdit: Colors.white,
    btnEditOutline: Colors.black,
    moreCatLink: Colors.black,
    editLink: Colors.black,
  },
  BgColors: {
    btnEdit: Colors.black,
  },
  BdrColors: {
    listItem: Colors.gray2,
    btnEdit: Colors.gray2,
  },
  FontFamily: {
    icon: FontFamily.fontAwesome,
  },
  FontSizes: {
    icon: (base_font * 0.87).toFixed(1) + 'rem',
    editSquareIcon: (base_font * 0.6875).toFixed(1) + 'rem',
  },
  Icons: {
    angleRight: Icons.angleright,
    plusIcon: Icons.pluscircle,
    editIcon: Icons.edit,
  },
};

export default TopCatgStyle;
