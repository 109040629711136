import { Colors } from '@styles/base/styled';

const PaginationStyle = {
  Colors: {
    paginationLinkActive: Colors.white,
    paginationLinkHover: Colors.white,
    linkDisable: Colors.gray1,
  },
  BgColors: {
    paginationLinkActive: Colors.black,
    paginationLinkHover: Colors.gray2,
  },
  BdrColors: {
    paginationLink: Colors.gray1,
    paginationLinkActive: Colors.black,
    paginationLinkHover: Colors.gray2,
  }
};

export default PaginationStyle;
