import { Colors, base_font, base_font_mb } from '@styles/graphite/styled';

const ApplyBtnStyle = {
  BgColors: {
    mailBtn: Colors.blue2,
    mailBtnHover: Colors.blue1,
  },
  Colors: {
    mailBtn: Colors.white,
  },
  FontSizes: {
    title: (base_font * 0.586).toFixed(1) + 'rem',
    textWrap: base_font + 'rem',
    textWrapMb: base_font_mb + 'rem',
  },
  LineHeights: {
    title: 1.5,
    textWrap: 1.5,
  },
  BdrColors: {
    popupFooter: Colors.gray1,
  },
};

export default ApplyBtnStyle;
