import { Colors, FontSizes, base_font, fontWeight } from '@styles/base/styled';

const JobInfoFooterStyle = {
  Colors: {
    colorDark: Colors.black,
    reportLab: Colors.black,
    reportedLab: Colors.black,
    deleteIcon: Colors.black,
    label: Colors.black,
    JobNoteActionBtn: Colors.black,
    NoteHeadWrap: Colors.black,
  },
  BdrColors: {
    jobActWrap: Colors.gray1,
    textAreaWrap: Colors.gray1,
    BtnWrapper: Colors.gray1,
    popupFooter: Colors.gray1,
    JobDetailsButton: Colors.gray1,
  },
  BgColors: {
    deleteNoteWrap: Colors.gray2,
  },
  FontSizes: {
    textArea: FontSizes.h5,
    deleteIcon: FontSizes.h3,
    applybtn: (base_font * 0.875).toFixed(1) + 'rem',
  },
  FontWeight: {
    icon: fontWeight.medium,
    deleteIcon: fontWeight.normal,
    notesHeader: fontWeight.medium,
    semibold: 600,
  },
};

export default JobInfoFooterStyle;
