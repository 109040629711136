import {
  Colors,
  FontFamily,
  Icons,
  FontSizes,
  base_font,
} from '@styles/base/styled';

const CompAwards = {
  Colors: {
    tagWrap: Colors.white,
    title: Colors.black,
  },
  BgColors: {
    tagWrap: Colors.black,
  },
  BdrColors: {
    awardsWrap: Colors.gray2,
  },
  FontSizes: {
    tagWrap: base_font * 0.75 + 'rem',
    arrow: base_font * 1.25 + 'rem',
    awardarrow: FontSizes.h5,
  },
  FontFamily: {
    arrowWrap: FontFamily.fontAwesome,
  },
  Icons: {
    chevronDown: Icons.chevrondown,
    chevronUp: Icons.chevronup,
  },
  FontWeight: {
    light: 300,
  },
};

export default CompAwards;
