import { Colors, base_font } from '@styles/base/styled';

const AuthStyle = {
  Colors: {
    link: Colors.white,
    dropdownMenu: Colors.black,
    mailBadgeCount: Colors.white,
    mailBoxIcon: Colors.white,
    linkFocus: Colors.white,
  },
  BgColors: {
    dropdown: Colors.white,
    dropdownMenuHover: Colors.gray2,
    mailBadgeCount: Colors.gray2,
  },
  BdrColors: {
    linkAfter: Colors.white,
    dropdownMenu: Colors.black,
  },
  FontSizes: {
    mailBadgeCount: (base_font * 0.75).toFixed(1) + 'rem',
    mailBoxIcon: (base_font * 1.3).toFixed(1) + 'rem',
    authUserName: base_font + 'rem',
    dropdownMenu: base_font + 'rem',
  },
  FontWeight: {
    mailBadgeCount: 700,
  },
};

export default AuthStyle;
