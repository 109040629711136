import { Colors } from '@styles/base/styled';

const SkillsWidgetStyle = {
  BrdrColor: {
    wrapperBrdr: Colors.gray2,
    wrapperTopBrdr: Colors.black,
  },
  FontWeights: {
    semibold: 600,
  },
};
export default SkillsWidgetStyle;
