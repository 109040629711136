import { Colors, base_font } from "@styles/base/styled";

const CompanySearchesStyle = {
  BdrColors: {
    listItem: Colors.gray2,
  },
  LineHeight: {
    listLink: 1.875,
  },
  FontSizes: {
    searchIcon: (base_font * 1.3).toFixed(1) + 'rem',
  },
};

export default CompanySearchesStyle;
