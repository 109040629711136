import { Colors } from '@styles/base/styled';

const MapStyle = {
  Colors: {
    iconColor: Colors.black,
  },
  BgColors: {
    mapWrapper: Colors.gray2,
    mapProvince: Colors.black,
    hoverProvince: Colors.gray1,
    iconBg: Colors.white,
    iconHoverBg: Colors.gray2,
  },
  BdrColor: {
    iconBorder: Colors.black,
  },
};

export default MapStyle;
