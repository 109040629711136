import { Colors, base_font } from '@styles/base/styled';

const UnAuthStyle = {
  Colors: {
    link: Colors.white,
    linkBtn: Colors.black,
    linkIcon: Colors.white,
    linkHover: Colors.white,
  },
  BgColors: {
    linkBtn: Colors.white,
  },
  BdrColors: {
    linkBtn: Colors.black,
    link: Colors.white,
  },
  FontSizes: {
    link: base_font + 'rem',
    linkIcon: (base_font * 1.5).toFixed(1) + 'rem',
    linkMob: (base_font * 0.875).toFixed(1) + 'rem',
  },
  LineHeights: {
    linkBtn: 1.5,
    link: base_font * 1.25 + 'rem',
  },
};

export default UnAuthStyle;
