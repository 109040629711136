import { Colors, FontSizes } from '@styles/graphite/styled';

const CompanyHiringStyle = {
  Colors: {
    buttonHover: Colors.white,
    title: Colors.blue2,
    titleHover: Colors.blue3,
  },
  BdrColors: {
    button: Colors.blue2,
    card: Colors.shadowyBlack,
  },
  BgColors: {
    button: Colors.white,
    buttonHover: Colors.blue2,
  },
  FontSizes: {
    btnPrimary: FontSizes.h5,
  },
  FontWeight: {
    medium: 500,
  },
};

export default CompanyHiringStyle;
