import { Colors, base_font } from '@styles/base/styled';

const PromoBannerStyle = {
  BgColors: {
    contentBg: Colors.gray1,
  },
  FontSizes: {
    title: base_font * 1.375 + 'rem',
    description: base_font * 0.875 + 'rem',
  },
  LineHeights: {
    title: 1.2,
  },
  FontWeights: {
    title: 600,
  },
};

export default PromoBannerStyle;
