import { Colors, base_font, base_font_mb } from '@styles/graphite/styled';
const SignUpSoftRegPopupStyle = {
  Colors: {
    softRegPopupWrapper: Colors.blue2,
    innerWrap: Colors.white,
    subHeading: Colors.blue22,
    btmImgWrap: Colors.gray39,
    drawerInnerWrap: Colors.blue22,
    title: Colors.azure,
    label: Colors.black1,
    requiredSign: Colors.orange5,
    Desc: Colors.blue22,
    regLabelBigInterview: Colors.black,
    bigInterviewInput: Colors.gray4,
    footNoteBigInterview: Colors.blue22,
    BtmImgWrapHead: Colors.blue22,
    BigReferedTxt: Colors.white,
    BigReferedTxtSm: Colors.blue22,
    AccountInfo: Colors.black7,
    StarIcon: Colors.black,
  },
  FontSizes: {
    formHeading: (base_font * 1.62).toFixed(2) + 'rem',
    footNote: base_font_mb + 'rem',
    footNoteSm: (base_font * 0.75).toFixed(2) + 'rem',
    secDesc: (base_font * 0.875).toFixed(2) + 'rem',
    title: base_font + 'rem',
    heading: (base_font * 1.375).toFixed(2) + 'rem',
    continueBtn: (base_font * 1.25).toFixed(2) + 'rem',
    SecDescBigInterview: (base_font * 0.875).toFixed(2) + 'rem',
    StarIcon: (base_font * 1.07).toFixed(2) + 'rem',
  },
};

export default SignUpSoftRegPopupStyle;
