import { Colors, FontSizes, base_font } from '@styles/graphite/styled';

const SidebarStyle = {
  Colors: {
    title: Colors.gray18,
  },
  BdrColors: {
    wrapper: Colors.shadowyBlack,
    wrapperBdrTop: Colors.blue1,
  },
  FontSizes: {
    sidebarTitle: (base_font * 1.3).toFixed(1) + 'rem',
    wrapperBottomIcon: (base_font * 0.8).toFixed(1) + 'rem',
    title: (base_font * 1.1).toFixed(1) + 'rem',
    smallSidebarTitle: FontSizes.h4,
  },
  FontWeight: {
    wrapperBottom: 600,
    title: 400,
  },
};

export default SidebarStyle;
