import {
  Colors,
  FontWeight,
  LineHeight,
  base_font,
} from '@styles/sapphire/styled';

const JobPltfrmStyle = {
  lineHeight: {
    info: LineHeight.lg,
  },
  FontWeight: {
    platform: FontWeight.semibold,
    normal: FontWeight.normal,
  },
  BdrColors: {
    tileCard: Colors.gray2,
  },
  FontSizes: {
    linkIcon: base_font * 0.875 + 'rem',
  },
};

export default JobPltfrmStyle;
