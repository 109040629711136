import { Colors } from '@styles/sapphire/styled';

const JobInfoStyle = {
  Colors: {
    listWrapper: Colors.black1,
    title: Colors.black1,
    wrapper: Colors.gray37,
  },
  BdrColors: {
    listWrapper: Colors.gray2,
    footNote: Colors.gray1,
    unAuthFootbrdr: Colors.gray32,
    jobSummary: Colors.gray37,
  },
  FontWeight: {
    title: 400,
    desc: 600,
  },
};

export default JobInfoStyle;
