import { Colors, base_font, lineHeight } from '@styles/base/styled';

const NavStyle = {
  Colors: {
    nav: Colors.black,
    link: Colors.white,
    linkHover: Colors.white,
    linkFocus: Colors.white,
  },
  BgColors: {
    nav: Colors.black,
    subNav: Colors.white,
    activeNav: Colors.black,
  },
  BdrColors: {
    linkBefore: Colors.white,
  },
  FontSizes: {
    link: base_font + 'rem',
    icon: base_font * 1.5 + 'rem',
    subNavLinkMob: (base_font * 0.875).toFixed(1) + 'rem',
    linkMob: (base_font * 0.875).toFixed(1) + 'rem',
  },
  LineHeights: {
    link: lineHeight.md,
  },
};

export default NavStyle;
