import { Colors, base_font } from '@styles/graphite/styled';

const CompanyCtaPopupStyle = {
  Colors: {
    highlightText: Colors.azure,
    descText: Colors.blue1,
  },
  FontSizes: {
    descText: (base_font * 1.5).toFixed(1) + 'rem',
  },
  FontWeight: {
    descText: 500,
  },
  LineHeight: {
    descText: 1.2,
    imageDesc: 1.2,
  },
};

export default CompanyCtaPopupStyle;
