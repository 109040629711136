import { Colors, base_font } from '@styles/sapphire/styled';

const CompRedesign = {
  Colors: {
    arrow: Colors.black,
    collapseHead: Colors.blue22,
    compName: Colors.blue22,
    compTitle: Colors.black,
    storyContent: Colors.blue1,
    authName: Colors.blue22,
    hiredAt: Colors.blue1,
    compDetail: Colors.black6,
    bannerHeading: Colors.white,
  },
  BgColors: {
    collapseCont: Colors.blue23,
    compDivision: Colors.blue23,
    compList: Colors.white,
    followCTRAWrap: Colors.blue2,
    successStoriesWrap: Colors.gray44,
    successStoriesWrapActive: Colors.blue24,
  },
  BdrColors: {
    collpaseCont: Colors.gray3,
    compHireWrap: Colors.gray37,
    compList: Colors.blue2,
    articleList: Colors.gray42,
  },
  FontSizes: {
    arrow: base_font + 'rem',
    articleLink: (base_font * 1.125).toFixed(1) + 'rem',
    collapseHead: base_font + 'rem',
    compExName: (base_font * 1.125).toFixed(1) + 'rem',
    compName: (base_font * 0.875).toFixed(1) + 'rem',
    compDetail: (base_font * 0.75).toFixed(1) + 'rem',
  },

  FontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    dark: 800,
  },
  BoxShadow: {
    articleList: Colors.gray43,
  },
};

export default CompRedesign;
