import { Colors, base_font } from '@styles/graphite/styled';

const UnAuthHeroSecStyle = {
  Colors: {
    colorWhite: Colors.white,
    heading: Colors.blue1,
    txtHighlight: Colors.azure,
    checkIcon: Colors.azure,
  },
  BgColors: {
    wrapper: Colors.blue8,
  },
  BdrColors: {
    wrapper: Colors.black,
  },

  FontSizes: {
    heading: (base_font * 2).toFixed(1) + 'rem',
    headingMob: (base_font * 1.675).toFixed(1) + 'rem',
    siteFeature: (base_font * 1.25).toFixed(1) + 'rem',
    siteFeatureMob: (base_font * 1.075).toFixed(1) + 'rem',
  },
};

export default UnAuthHeroSecStyle;
