import {
  Colors,
  FontFamily,
  FontWeight,
  Icons,
  LineHeight,
  base_font,
} from '@styles/sapphire/styled';

const IndPlatformStyle = {
  Colors: {
    subHeadIcon: Colors.blue1,
  },
  BgColors: {
    container: Colors.blue4,
    aboutWrapper: Colors.white,
  },
  BdrColors: {
    aboutWrapper: Colors.gray1,
  },
  FontSizes: {
    infoTitle: base_font * 1.125 + 'rem',
    subHeading: base_font * 1.375 + 'rem',
    subHeadIcon: base_font * 1.75 + 'rem',
  },
  fontWeight: {
    mainHeading: FontWeight.semibold,
    subHeading: FontWeight.semibold,
    backtoLink: FontWeight.normal,
  },
  lineHeight: {
    para: LineHeight.lg,
  },
  FontFamily: {
    backtoLink: FontFamily.fontAwesome,
  },
  Icons: {
    backtoLink: Icons.angleright,
  },
};

export default IndPlatformStyle;
