import { Colors, base_font } from '@styles/base/styled';

const HeroBannerStyle = {
  BgColors: {
    section: Colors.gray2,
    imgWrap: Colors.gray2,
  },
  Colors: {
    textHighlight: Colors.gray1,
    imgHeading: Colors.white,
  },
  FontSizes: {
    heading: (base_font * 2.25).toFixed(1) + 'rem',
  },
};

export default HeroBannerStyle;
