import {
  Colors,
  FontFamily,
  FontSizes,
  Icons,
  base_font,
} from '@styles/sapphire/styled';

const OverviewStyle = {
  FontSizes: {
    toggle: base_font + 'rem',
    arrowBtn: base_font + 'rem',
    arrowiconfs: (base_font * 0.8125).toFixed(1) + 'rem',
    quoteiconfs: base_font * 2 + 'rem',
    checkouticon: (base_font * 0.75).toFixed(1) + 'rem',
    tablink: (base_font * 1.1875).toFixed(1) + 'rem',
    quoteiconmgm: FontSizes.h5,
  },
  BgColor: {
    divisionlinkbg: Colors.blue4,
  },
  Colors: {
    colordark: Colors.black,
    toggle: Colors.blue2,
    quoteicon: Colors.orange2,
    authname: Colors.blue2,
    bordercolor1: Colors.gray3,
    bordercolor2: Colors.gray2,
    mutedtext: Colors.gray6,
    arrowBtn: Colors.gray19,
    arrowBtnHover: Colors.black,
    storyAuthorShadow: Colors.shadowSharp,
  },
  FontFamily: {
    iconfontfamily: FontFamily.fontAwesome,
  },
  Icons: {
    quotesleft: Icons.quotesleft,
    quotesright: Icons.quotesright,
  },
  FontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
  },
};

export default OverviewStyle;
