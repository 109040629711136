import { Colors, FilterGrayScale } from '@styles/base/styled';

const HeadBannerStyle = {
  Colors: {
    bannerWrap: Colors.white,
  },
  BgColors: {
    wrapper: Colors.gray2,
    wrapperbg: Colors.gray2,
  },
  BdrColors: {
    wrapper: Colors.black,
  },
  Filter: {
    wrapper: FilterGrayScale.active,
  },
};

export default HeadBannerStyle;
