import { Colors, base_font } from '@styles/graphite/styled';

const FormControlStyle = {
  Colors: {
    DarkColor: Colors.black,
    ErrorMsgWrap: Colors.red1,
    Select: Colors.black,
  },
  BdrColors: {
    select: Colors.gray1,
    ErrorMsgWrap: Colors.red3,
    selectCountry:Colors.gray48,
  },
  BgColors: {
    ErrorMsgWrap: Colors.red2,
  },
  FontSizes: {
    bottomTxt: (base_font * 0.875).toFixed(1) + 'rem',
  },
  FontWeight: {
    ErrorMsgWrap: 400,
  },
};

export default FormControlStyle;
