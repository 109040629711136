import {
  Colors,
  FontFamily,
  Icons,
  base_font,
  lineHeight,
} from '@styles/base/styled';

const CatLinkStyle = {
  Colors: {
    linkHover: Colors.gray1,
    headColor: Colors.black,
  },
  BgColors: {
    list: Colors.white,
    secWrapperMob: Colors.gray2,
  },
  BdrColors: {
    wrapper: Colors.gray2,
  },
  FontFamily: {
    linkIcon: FontFamily.fontAwesome,
  },
  FontWeight: {
    normal: 400,
    medium: 500,
  },
  FontSizes: {
    linkIcon: base_font * 0.875 + 'rem',
  },
  Icons: {
    linkIcon: Icons.angleright,
  },
  lineHeight: {
    link: lineHeight.xl,
  },
};

export default CatLinkStyle;
