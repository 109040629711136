import { Colors, FontFamily, Icons, base_font } from '@styles/base/styled';

const MultiFilterStyle = {
  Colors: {
    itemSelected: Colors.white,
  },
  BgColors: {
    button: Colors.white,
    dropList: Colors.white,
    listSelected: Colors.gray2,
    listHighlight: Colors.gray1,
    list: Colors.white,
    listHover: Colors.gray1,
    itemSelected: Colors.black,
  },
  BdrColors: {
    button: Colors.black,
    dropList: Colors.black,
    dropDownFocus: Colors.black,
  },
  FontSizes: {
    heading: base_font + 'rem',
    crossIcon: (base_font * 0.625).toFixed(1) + 'rem',
    caretIcon: (base_font * 0.69).toFixed(1) + 'rem',
  },

  FontFamily: {
    iconFont: FontFamily.fontAwesome,
  },
  Icons: {
    chevrondown: Icons.chevrondown,
  },
};

export default MultiFilterStyle;
