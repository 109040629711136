interface Experiment {
  id: string;
  name: string;
  isJSExperiment: boolean;
  isVisitor: boolean;
  logTraits: boolean;
  conductForOldUsers: boolean;
  getAfterSwap: boolean;
  scope: string;
  variantNameArr: { [key: string]: string };
}

export const ABtestData = {
  DISABLE_TESTS: 'disabletests',
  RUNTEST: 'runtest',
};

interface PortalExperiments {
  [key: string]: Experiment;
}

export const deviceTypeRegex = {
  mobile: /mobile|android|iphone|ipad|ipod|Android|BlackBerry/i,
  desktop: /windows|macintosh|linux|mac os/i,
};

export enum ScopeDevices {
  mobile = 'mobile',
  desktop = 'desktop',
}

export const FJPortalExperiments: PortalExperiments = {
  fjWizardExperimentSpa: {
    id: '2a906cb4-9b19-4c2d-8a26-b1b39bd7b94b',
    name: 'FJ Wizard Funnel SPA Experiment',
    isJSExperiment: true,
    isVisitor: false,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: '',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Wizard funnel',
    },
  },
  RMCJBSTableLocDateDescExperimentDesktop: {
    id: 'e48dd570-f250-4f8e-9643-e528105dab3b',
    name: 'FJ Job Table And Inset ALL Desktop V1 LTV',
    isJSExperiment: false,
    isVisitor: false,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'desktop',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Location + Date + Short Description on Table',
      '4': 'Location + Date + No Short Description on Table',
    },
  },
  RMCJBSTableLocDateDescExperimentMobile: {
    id: 'c81f0adc-4534-4ad6-a249-e24a123168e2',
    name: 'FJ Job Table And Inset ALL Mobile V1 LTV',
    isJSExperiment: false,
    isVisitor: false,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'mobile',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Location + Date + Detail Page ',
      '4': 'Location + Date + Detail Pop-up ',
    },
  },
  fjSearchPageExperimentDesktop: {
    id: '94411c80-7fcd-4fc4-9cf4-7f56b2ca8ccf',
    name: 'FJ SEARCH ALL DESKTOP V2 LTV',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'desktop',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Show Total Job Count in Search Results',
    },
  },
};
