import { Colors, base_font } from '@styles/base/styled';

const PltfrmTypeStyle = {
  Colors: {
    type: Colors.black,
  },
  FontSizes: {
    infoMob: base_font * 0.875 + 'rem',
  },
};

export default PltfrmTypeStyle;
