import {
  Colors,
  FontSizes,
  base_font,
  base_font_mb,
} from '@styles/sapphire/styled';

const EmployersStyle = {
  BgColors: {
    bannerWrap: Colors.blue18,
    testimonialWrapper: Colors.blue2,
    listItem: Colors.white,
  },

  Colors: {
    bannerTitle: Colors.blue1,
    bannerDesc: Colors.black,
    title: Colors.white,
    itemList: Colors.blue2,
    star: Colors.white,
    secoTitle: Colors.black,
    secoContact: Colors.black,
    linkWrapper: Colors.black,
    loomeeTitle: Colors.blue1,
    icon: Colors.gray28,
    naamalTitle: Colors.blue1,
    naamalIcon: Colors.blue1,
    availabilty: Colors.black,
    teleLink: Colors.blue1,
    teleLinkIcon: Colors.black,
    needhelphead: Colors.black,
    programcordinator: Colors.black,
    subHeading: Colors.black,
    workwellicon: Colors.blue1,
    followUs: Colors.facebook,
    colTitle: Colors.blue1,
    name: Colors.white,
    arrowHover: Colors.white,
    arrowPrevNextBtn: Colors.blue21,
  },
  FontWeights: {
    otherTitle: 500,
    secoTitle: 600,
    item: 600,
    loomeeTitle: 500,
    naamalTitle: 500,
    naamalLink: 500,
    agncUsefulLinks: 500,
    contact: 500,
    contactTitle: 600,
    followUs: 500,
    colTitle: 500,
  },
  BrdColors: {
    leftTxtWrapper: Colors.gray3,
    listItem: Colors.gray27,
    borderedCard: Colors.gray3,
  },
  LineHeights: {
    otherTitle: 1.2,
    address: 1.5,
    secoTitle: 1.4,
    naamalTitle: 1.2,
    agncUsefulLinks: 1.1,
    contactTitle: 1.2,
  },
  Shadows: {
    listItem: Colors.gray15,
  },
  FontSizes: {
    bannerTitle: (base_font * 1.75).toFixed(1) + 'rem',
    bannerTitleSm: (base_font * 1.62).toFixed(1) + 'rem',
    title: FontSizes.h1,
    titleSm: (base_font * 1.625).toFixed(1) + 'rem',
    bannerDesc: base_font + 'rem',
    bannerDescSm: FontSizes.h6,
    description: base_font + 'rem',
    otherTitle: FontSizes.h3,
    otherTitleSm: FontSizes.h4,
    contact: base_font + 'rem',
    commonmobilefs: base_font_mb + 'rem',
    address: base_font + 'rem',
    addressSm: FontSizes.h6,
    itemList: base_font + 'rem',
    star: (base_font * 1.625).toFixed(1) + 'rem',
    swiperBtn: (base_font * 1.87).toFixed(1) + 'rem',
    secoTitle: base_font + 'rem',
    secoContact: base_font + 'rem',
    linkWrapper: base_font + 'rem',
    listItem: base_font + 'rem',
    loomeeContact: base_font + 'rem',
    loomeeContactSm: base_font_mb + 'rem',
    item: base_font + 'rem',
    chatIcon: base_font_mb + 'rem',
    loomeeTitle: FontSizes.h2,
    loomeeTitleSm: (base_font * 1.37).toFixed(1) + 'rem',
    icon: FontSizes.h4,
    naamalTitle: FontSizes.h2,
    naamalTitleSm: (base_font * 1.37).toFixed(1) + 'rem',
    naamalLink: (base_font * 1.31).toFixed(1) + 'rem',
    naamalIcon: FontSizes.h4,
    agncContactIcon: FontSizes.h6,
    agncUsefulLinks: (base_font * 1.07).toFixed(1) + 'rem',
    contactSm: FontSizes.h6,
    agncUsefulLinksSm: base_font + 'rem',
    contactTitle: FontSizes.h2,
    followUs: (base_font * 1.35).toFixed(1) + 'rem',
    followUsSm: (base_font * 1.37).toFixed(1) + 'rem',
    contactTitleSm: (base_font * 1.37).toFixed(1) + 'rem',
    facebook: (base_font * 2).toFixed(1) + 'rem',
    facebookSm: (base_font * 1.37).toFixed(1) + 'rem',
    columnWrapper: base_font + 'rem',
    columnWrapperSm: base_font_mb + 'rem',
    needhelphead: (base_font * 1.5).toFixed(1) + 'rem',
    programcordinator: base_font + 'rem',
    connectLink: FontSizes.h4,
    connectMsg: FontSizes.h5,
    connectMsgRural: FontSizes.h4,
  },
};

export default EmployersStyle;
