import { Colors, FontSizes, base_font } from '@styles/base/styled';

const JobNoteStyle = {
  Colors: {
    colorDark: Colors.black,
    deleteIcon: Colors.gray1,
    label: Colors.black,
    WriteText: Colors.black,
    NoteHeadWrap: Colors.gray1,
    placeHolder: Colors.gray1,
  },
  BdrColors: {
    jobActWrap: Colors.gray1,
    textAreaWrap: Colors.gray1,
    BtnWrapper: Colors.gray1,
  },
  BgColors: {
    deleteNoteWrap: Colors.gray1,
    textAreaWrap: Colors.gray1,
    textAreaWrapfocus: Colors.white,
  },
  FontSizes: {
    textArea: FontSizes.h5,
    deleteIcon: FontSizes.h3,
    textAreaMob: FontSizes.h6,
    NotePencilWrap: (base_font * 0.75).toFixed(1) + 'rem', //1.2rem
  },
};

export default JobNoteStyle;
