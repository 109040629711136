import { Colors } from '@styles/graphite/styled';

const ResumeProfileStyle = {
  Colors: {
    DateVal: Colors.gray6,
  },
  BdrColors: {
    ListWrapper: Colors.shadowyBlack,
    ListWrapperShadow: Colors.shadowSharp,
  },
};

export default ResumeProfileStyle;
