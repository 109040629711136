import { Colors, base_font } from '@styles/base/styled';

const ApplicationStyle = {
  Colors: {
    jobType: Colors.black,
    dropableListFocus: Colors.gray2,
    applyDate: Colors.black,
    trashCan: Colors.gray1,
    stickyIcon: Colors.black,
    stickySelected: Colors.gray2,
    appDate: Colors.black,
    deleteIcon: Colors.gray1,
    notehead: Colors.black,
    ToastLink: Colors.black,
    EmptyStateWrap: Colors.gray1,
    toast: Colors.black,
  },
  BgColors: {
    applicationWrap: Colors.white,
    toast: Colors.gray1,
    checkListWrap: Colors.white,
    notesWrap: Colors.gray1,
  },
  BdrColors: {
    borderSec: Colors.gray2,
    applicationWrap: Colors.gray2,
    checkListWrap: Colors.gray2,
    checkListTop: Colors.black,
    borderNotes: Colors.gray2,
    AppApplyGuideImg: Colors.gray2,
    EmptyStateWrapper: Colors.gray1,
    EmptyStateWrapperDash: Colors.gray1,
  },
  FontSizes: {
    trashIcon: (base_font * 0.875).toFixed(1) + 'rem',
    deleteIcon: (base_font * 1.563).toFixed(1) + 'rem',
    stickyIcon: (base_font * 2).toFixed(1) + 'rem',
    timeIcon: base_font * 0.875 + 'rem',
    editText: (base_font * 0.875).toFixed(1) + 'rem',
    appliedOnText: (base_font * 0.875).toFixed(1) + 'rem',
  },
  FontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
  },
};

export default ApplicationStyle;
