import { Colors, base_font } from '@styles/sapphire/styled';

const JobSearchStyle = {
  Colors: {
    inputBtn: Colors.white,
    outlineBtn: Colors.orange1,
  },
  BgColors: {
    inputBtn: Colors.orange2,
    shadowSearch: Colors.shadowSharp,
  },
  FontSizes: {
    input: base_font * 0.9375 + 'rem',
    inputMob: base_font * 0.875 + 'rem',
    searchIcon: (base_font * 1.25).toFixed(1) + 'rem',
  },
};
export default JobSearchStyle;
