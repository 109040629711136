import { Button } from '@license-admin/boldfjcomponents';
import { Wrapper } from './styled';

export default function FooterRibbon() {
  return (
    <Wrapper>
      <span>Find Top Remote and Flexible Candidates!</span>
      <Button
        text="Get Started Today!"
        url="/employers-post-remote-jobs"
        buttonType="tertiary"
        ButtonClass="large-width"
      />
    </Wrapper>
  );
}
