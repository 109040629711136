import {
  Colors,
  FontFamily,
  Icons,
  base_font,
  fontWeight,
} from '@styles/base/styled';

const toolTipStyle = {
  BgColors: {
    secWrap: Colors.white,
    jobBtnWrap: Colors.gray2,
    remoteWrokTipBg: Colors.gray1,
    popupBg: Colors.white,
    saveLinkHover: Colors.gray2,
    saveLinkHoverBg: Colors.gray2,
    saveLinkHoverDropBg: Colors.white,
    infoIconText: Colors.black8,
  },
  BdrColors: {
    secWrap: Colors.gray2,
    jobBtnWrap: Colors.gray1,
    remoteTipBrdrBefore: Colors.gray2,
    remoteTipBrdrAfter: Colors.gray2,
    remoteWrokTipBrdr: Colors.gray2,
    popupBrdr: Colors.shadowSharp,
  },
  Colors: {
    secWrap: Colors.black,
    iconWrap: Colors.black,
    infoIcon: Colors.gray1,
    count: Colors.black,
    tipsTitle: Colors.black,
    tipsText: Colors.black,
    jobBtnTxt: Colors.black,
    filterShadow: Colors.shadowyBlack,
    checkWrapInput: Colors.shadowSharp,
    saveLinkHover: Colors.gray1,
    saveSearchDrop: Colors.gray2,
    infoIconText: Colors.white,
    saveBtnWrapText: Colors.black,
  },
  FontSizes: {
    infoIcon: (base_font * 0.875).toFixed(1) + 'rem',
    tipsText: (base_font * 0.875).toFixed(1) + 'rem',
    iconWrap: (base_font * 1.5).toFixed(1) + 'rem',
    filterBtn: (base_font * 0.625).toFixed(1) + 'rem',
    resultDeskHead: (base_font * 1.5).toFixed(1) + 'rem',
    resultHead: base_font + 'rem',
    savedSearchMenu: base_font + 'rem',
    infoIconText: (base_font * 0.75).toFixed(1) + 'rem',
    resultHeading: (base_font * 1.125).toFixed(1) + 'rem',
    innerWrap: (base_font * 0.875).toFixed(1) + 'rem',
  },
  FontWeight: {
    closeIcon: fontWeight.normal,
    tipsTitle: fontWeight.semibold,
    tipsText: fontWeight.medium,
  },
  FontFamily: {
    closeIcon: FontFamily.fontAwesome,
    resultHeading: FontFamily.fontFamilyOpensense,
  },
  Icons: {
    arrow: Icons.pluscircle,
  },
};

export default toolTipStyle;
