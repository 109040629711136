import { Colors, base_font } from '@styles/base/styled';

const UpComingEventStyle = {
  Colors: {
    type: Colors.black,
  },
  FontSizes: {
    type: (base_font * 0.875).toFixed(1) + 'rem',
  },
};

export default UpComingEventStyle;
