import { Colors, base_font } from '@styles/base/styled';

const UnAuthHeroSecStyle = {
  Colors: {
    heading: Colors.black,
    txtHighlight: Colors.white,
    checkIcon: Colors.black,
  },
  BgColors: {
    wrapper: Colors.gray1,
  },
  BdrColors: {
    wrapper: Colors.black,
  },

  FontSizes: {
    heading: base_font * 2 + 'rem',
    headingMob: (base_font * 1.675).toFixed(1) + 'rem',
    siteFeature: (base_font * 1.25).toFixed(1) + 'rem',
    siteFeatureMob: (base_font * 1.075).toFixed(1) + 'rem',
  },
};

export default UnAuthHeroSecStyle;
