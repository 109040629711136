import { base_font } from '@styles/base/styled';
import { Colors, FontSizes } from '@styles/graphite/styled';

const JobNoteStyle = {
  Colors: {
    colorDark: Colors.black1,
    deleteIcon: Colors.gray10,
    label: Colors.blue2,
    NoteHeadWrap: Colors.gray29,
    WriteText: Colors.blue2,
    StickyIcon: Colors.blue2,
    PencilIcon: Colors.blue1,
    placeHolder: Colors.blue22,
  },
  BdrColors: {
    jobActWrap: Colors.gray1,
    textAreaWrap: Colors.gray1,
    BtnWrapper: Colors.gray1,
  },
  BgColors: {
    deleteNoteWrap: Colors.shadowyWhite,
    textAreaWrap: Colors.blue10,
    textAreaWrapfocus: Colors.white,
  },
  FontSizes: {
    textArea: FontSizes.h5,
    deleteIcon: FontSizes.h3,
    textAreaMob: FontSizes.h6,
    NotePencilWrap: (base_font * 0.75).toFixed(1) + 'rem', //1.2rem
  },
};

export default JobNoteStyle;
