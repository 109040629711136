import { Colors, base_font } from '@styles/base/styled';

const ChipsStyle = {
  Colors: {
    chipChecked: Colors.white,
    chip: Colors.black,
  },
  BgColors: {
    chip: Colors.white,
    chipHover: Colors.gray2,
    chipChecked: Colors.black,
  },
  BdrColors: {
    chip: Colors.gray1,
  },
  FontSizes: {
    heading: base_font + 'rem',
  },
};

export default ChipsStyle;
