import {
  Colors,
  FontWeight,
  Icons,
  LineHeight,
  base_font,
} from '@styles/graphite/styled';

const jobGuideInfoStyle = {
  BgColors: {
    header: Colors.gray16,
    wrap: Colors.white,
  },
  BdrColors: {
    header: Colors.gray2,
    wrap: Colors.gray1,
    popoverArrow: Colors.white,
    popoverArrowBefore: Colors.gray1,
  },
  Colors: {
    popoverHeader: Colors.black,
    listItem: Colors.black,
  },
  FontSizes: {
    wrap: (base_font * 0.875).toFixed(1) + 'rem',
    listItem: (base_font * 0.75).toFixed(1) + 'rem',
  },
  FontWeight: {
    popoverHeader: FontWeight.semibold,
    listItem: FontWeight.normal,
  },
  Icons: {
    locationIcon: Icons.location,
  },
  LineHeight: {
    wrap: LineHeight.xl,
    desc: LineHeight.normal,
  },
};

export default jobGuideInfoStyle;
