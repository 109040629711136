import React, { useEffect, useState } from 'react';
import { isAuthUser } from './common';
import { ILoggedInUser } from './shared.types';
interface IGlobalContextProps {
  isUserLoggedIn: boolean;
  UserId: string;
  getUpdateUserDetail: () => void;
}

export const GlobalContext = React.createContext<IGlobalContextProps>({
  isUserLoggedIn: false,
  UserId: '',
  getUpdateUserDetail: Function,
});

export const GlobalContextProvider = (props: any): any => {
  const [userDetails, setUserDetails] = useState<ILoggedInUser>({
    IsUserLoggedIn: false,
    User: {
      AccDisplayName: '',
      CreatedOn: '',
      Role: 0,
      UserId: '',
    },
  });

  useEffect(() => {
    if (!props.isLoggedIn) {
      getUpdateUserDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.IsUserLoggedIn]);

  const getUpdateUserDetail = (): boolean => {
    const userStatusCookie = isAuthUser();
    setUserDetails(userStatusCookie);
    return userStatusCookie.IsUserLoggedIn;
  };

  return (
    <GlobalContext.Provider
      value={{
        isUserLoggedIn: userDetails.IsUserLoggedIn,
        UserId: userDetails.User.UserId,
        getUpdateUserDetail: getUpdateUserDetail,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
