import { Colors, FontFamily, FontSizes, Icons } from '@styles/base/styled';

const SearchesChecklist = {
  Colors: {
    checklisttextdark: Colors.black,
    checklisttime: Colors.gray2,
  },
  BdrColors: {
    searchtopborder: Colors.gray1,
    searchcardbrdr: Colors.gray2,
  },
  FontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
  },
  FontSizes: {
    clocktimefont: FontSizes.h5,
  },
  FontFamilies: {
    iconfonts: FontFamily.fontAwesome,
  },
  Icons: {
    clocklefticon: Icons.clockRotateLeft,
    tickicon: Icons.tickicon,
    circlecross: Icons.circlecross,
  },
};
export default SearchesChecklist;
