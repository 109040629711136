import {
  checkIsServerCall,
  localStorageGetItem,
  localStorageSetItem,
  readCookie,
} from '@components/common';
import {
  TIMEOUT_DURATION,
  TWENTY_FOUR_HOUR_CACHE,
} from '@components/common/constant';
import { isValidJSON } from 'helper';
import cache from 'memory-cache';
import { boldLoggerServerSide, logErrorServer } from './ApiHelpers';

interface BlobResponse<T> {
  statusCode: number;
  configData: T;
  error: string;
  message: string;
}
interface ApiResponse<T> {
  success: boolean;
  data: T | null;
  error?: string;
}

class ApiClient {
  private baseUrl: string;
  // data is cached for one day //
  private cacheExpirationTime: number = 60 * 1000 * 60 * 24;
  private blobBaseUrl: string =
    (process.env.NEXT_PUBLIC_BLOB_URL as string) || '';

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getConfig<U>(
    url: string,
    hasBlobURL: boolean = false
  ): Promise<BlobResponse<U>> {
    try {
      let apiURL = `${this.blobBaseUrl}${url}`;
      if (hasBlobURL) {
        apiURL = url;
      }
      const cachedResponse = cache.get(apiURL);
      if (cachedResponse) {
        return {
          statusCode: 200,
          configData: cachedResponse.data as U,
          error: '',
          message: '',
        };
      } else {
        const response = await fetch(apiURL);
        let data = await response.json();
        cache.put(apiURL, {
          apiURL,
          data,
          timestamp: 2 * 60 * 60 * 1000,
        });
        return {
          statusCode: response.status,
          configData: data as U,
          error: '',
          message: '',
        };
      }
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      throw new Error('Failed to fetch data from BLOB' + url + errorMessage);
    }
  }

  async getWithServerCache<T>(
    endpoint: string,
    cookieHeader?: string
  ): Promise<ApiResponse<T>> {
    const url = `${checkIsServerCall(this.baseUrl)}/${endpoint}`;

    if (typeof window === 'undefined') {
      boldLoggerServerSide(url, cookieHeader || '');
    }
    try {
      //force cache delete
      if (readCookie('purgeServeCache', cookieHeader)) {
        cache.del;
      }

      const cachedResponse = cache.get(url);
      if (cachedResponse) {
        return {
          success: true,
          data: cachedResponse,
        };
      } else {
        const response = await fetch(url, {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            cookie: cookieHeader || '',
          },
          ...(typeof window === 'undefined' && {
            signal: AbortSignal.timeout(TIMEOUT_DURATION),
          }),
        });
        const data: T = await response.json();
        if (!response.ok) {
          logErrorServer(
            `FXJ RMC_JOBS Client error: Status Code: Status Code: ${response.status}`,
            `Failed to fetch data from api: ${url} [API STATUS]: ${response.statusText}`,
            true,
            cookieHeader
          );
          return {
            success: false,
            data: null,
          };
        }

        cache.put(url, data, TWENTY_FOUR_HOUR_CACHE);

        return {
          success: true,
          data: data,
        };
      }
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      logErrorServer(
        `FXJ RMC_JOBS Client error:`,
        `Failed to fetch data from api: ${url}, Error: ${errorMessage}`,
        true,
        cookieHeader
      );
      console.error(`Error fetching data from ${url}:`, error);
      return {
        success: false,
        data: null,
        error: 'Error message',
      };
    }
  }

  async get<T>(
    endpoint: string,
    cookieHeader?: string
  ): Promise<ApiResponse<T>> {
    const url = `${checkIsServerCall(this.baseUrl)}/${endpoint}`;

    if (typeof window === 'undefined') {
      boldLoggerServerSide(url, cookieHeader || '');
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          cookie: cookieHeader || '',
        },
        ...(typeof window === 'undefined' && {
          signal: AbortSignal.timeout(TIMEOUT_DURATION),
        }),
      });

      if (!response.ok) {
        logErrorServer(
          `FXJ RMC_JOBS Client error: Status Code: Status Code: ${response.status}`,
          `Failed to fetch data from api: ${url} [API STATUS]: ${response.statusText}`,
          true,
          cookieHeader
        );
      }

      const data: T = await response.json();

      return {
        success: true,
        data,
      };
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      logErrorServer(
        `FXJ RMC_JOBS Client error:`,
        `Failed to fetch data from api: ${url}, Error: ${errorMessage}`,
        true,
        cookieHeader
      );
      console.error(`Error fetching data from ${url}:`, error);
      return {
        success: false,
        data: null,
        error: 'Error message',
      };
    }
  }

  async fetchAndCacheWithExpiration<T>(
    endpoint: string,
    email?: String,
    cookieHeader?: string
  ): Promise<ApiResponse<T>> {
    const url = `${checkIsServerCall(this.baseUrl)}/${endpoint}`;

    const cacheKey = `${endpoint}${email}`;
    const cachedData = localStorageGetItem(cacheKey);

    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      if (Date.now() - parsedData.timestamp < this.cacheExpirationTime) {
        return {
          success: true,
          data: parsedData.data,
        };
      }
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          cookie: cookieHeader || '',
        },
        ...(typeof window === 'undefined' && {
          signal: AbortSignal.timeout(TIMEOUT_DURATION),
        }),
      });

      if (!response.ok) {
        logErrorServer(
          `FXJ RMC_JOBS Client error: Status Code: ${response.status}`,
          `Failed to fetch data from api: ${url} [API STATUS]: ${response.statusText}`,
          true,
          cookieHeader
        );
        return {
          success: false,
          data: null,
          error: `Status Code: ${response.status}`,
        };
      }

      const data: T = await response.json();

      // Cache the fetched data with timestamp in localStorage
      localStorageSetItem(
        cacheKey,
        JSON.stringify({ data, timestamp: Date.now() })
      );

      return {
        success: true,
        data,
      };
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      logErrorServer(
        `FXJ RMC_JOBS Client error:`,
        `Failed to fetch data from api: ${url}, Error: ${errorMessage}`,
        true,
        cookieHeader
      );
      console.error(`Error fetching data from ${url}:`, errorMessage);
      return {
        success: false,
        data: null,
        error: 'Error message',
      };
    }
  }

  async post<T, U>(
    endpoint: string,
    body?: U,
    cookieHeader?: string
  ): Promise<ApiResponse<T>> {
    const url = `${checkIsServerCall(this.baseUrl)}/${endpoint}`;

    if (typeof window === 'undefined') {
      boldLoggerServerSide(url, cookieHeader || '', body);
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          cookie: cookieHeader || '',
        },
        body: JSON.stringify(body),
        ...(typeof window === 'undefined' && {
          signal: AbortSignal.timeout(TIMEOUT_DURATION),
        }),
      });

      const data: T = await response.json();

      return {
        success: true,
        data,
      };
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      console.error(`Error fetching data from ${url}:`, errorMessage);
      throw new Error('Failed to fetch data from API' + url + errorMessage);
    }
  }

  async put<T, U>(
    endpoint: string,
    body?: U,
    cookieHeader?: string
  ): Promise<ApiResponse<T>> {
    const url = `${checkIsServerCall(this.baseUrl)}/${endpoint}`;

    if (typeof window === 'undefined') {
      boldLoggerServerSide(url, cookieHeader || '', body);
    }

    try {
      const response = await fetch(url, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          cookie: cookieHeader || '',
        },
        body: JSON.stringify(body),
        ...(typeof window === 'undefined' && {
          signal: AbortSignal.timeout(TIMEOUT_DURATION),
        }),
      });

      const data: T = await response.json();

      return {
        success: true,
        data,
      };
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      console.error(`Error fetching data from ${url}:`, errorMessage);
      throw new Error('Failed to fetch data from API' + url + errorMessage);
    }
  }

  async patch<T, U>(
    endpoint: string,
    body?: U,
    cookieHeader?: string
  ): Promise<ApiResponse<T>> {
    const url = `${checkIsServerCall(this.baseUrl)}/${endpoint}`;

    if (typeof window === 'undefined') {
      boldLoggerServerSide(url, cookieHeader || '', body);
    }

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          cookie: cookieHeader || '',
        },
        body: JSON.stringify(body),
        ...(typeof window === 'undefined' && {
          signal: AbortSignal.timeout(TIMEOUT_DURATION),
        }),
      });

      const data: T = await response.json();

      return {
        success: true,
        data,
      };
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      console.error(`Error fetching data from ${url}:`, errorMessage);
      throw new Error('Failed to fetch data from API' + url + errorMessage);
    }
  }

  async delete<T>(endpoint: string): Promise<ApiResponse<T>> {
    const url = `${checkIsServerCall(this.baseUrl)}/${endpoint}`;

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        ...(typeof window === 'undefined' && {
          signal: AbortSignal.timeout(TIMEOUT_DURATION),
        }),
      });
      const data: T = await response.json();
      return {
        success: true,
        data,
      };
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      console.error(
        `Error posting data to ${this.baseUrl}${url}:`,
        errorMessage
      );
      throw new Error('Failed to post data to API' + url + errorMessage);
    }
  }
}

export default ApiClient;
