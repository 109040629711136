import { Colors, base_font } from '@styles/graphite/styled';

const CompanyFlexibilityStyle = {
  Colors: {
    heading4: Colors.black,
  },
  FontSizes: {
    note: (base_font * 0.75).toFixed(1) + 'rem',
  },
};

export default CompanyFlexibilityStyle;
