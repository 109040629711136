import { Colors, base_font } from '@styles/graphite/styled';

const telecommuteJobStyle = {
  Colors: {
    searchButton: Colors.white,
  },
  BgColors: {
    search: Colors.white,
    mbSearch: Colors.white,
    searchButton: Colors.azure,
  },
  BdrColors: {
    searchWrap: Colors.gray48,
  },
  FontSizes: {
    searchButton: base_font * 1.3 + 'rem',
    description: base_font * 0.875 + 'rem',
  },
};
export default telecommuteJobStyle;
