import { Colors, base_font } from '@styles/sapphire/styled';

const NotOrdinaryStyle = {
  Colors: {
    bubblePara: Colors.orange1,
  },
  FontSizes: {
    secHeading: (base_font * 1.625).toFixed(1) + 'rem',
    bubblePara: (base_font * 1.125).toFixed(1) + 'rem',
  },
};

export default NotOrdinaryStyle;
