import { Colors, base_font } from '@styles/sapphire/styled';
const BenefitStyle = {
  Colors: {
    title: Colors.orange1,
  },
  BdrColors: {
    wrapper: Colors.orange2,
  },
  FontSizes: {
    secHeading: (base_font * 1.75).toFixed(1) + 'rem',
    title: (base_font * 1.25).toFixed(1) + 'rem',
  },
};

export default BenefitStyle;
