import { Colors } from '@styles/base/styled';

const ToasterSectionStyle = {
  BgColors: {
    secWrap: Colors.gray2,
    info: Colors.gray2,
    bgalert: Colors.gray2,
    success: Colors.gray2,
    tips: Colors.gray2,
  },
  BdrColors: {
    secWrap: Colors.gray1,
    bdralert: Colors.gray1,
    info: Colors.gray2,
    success: Colors.gray2,
    tips: Colors.gray2,
  },
  Colors: {
    icon: Colors.black,
    coloralert: Colors.white,
    info: Colors.gray2,
    coloralert1: Colors.gray2,
    success: Colors.gray2,
    tips: Colors.black,
  },
};

export default ToasterSectionStyle;
