import { Colors } from '@styles/base/styled';

const HeaderStyle = {
  BgColors: {
    header: Colors.white,
  },
  BdrColors: {
    link: Colors.black,
  },
};

export default HeaderStyle;
