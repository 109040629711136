import { base_font } from '@styles/graphite/styled';

const PopularJobSearch = {
  FontSizes: {
    heading: (base_font * 1.5).toFixed(1) + 'rem',
    links: (base_font * 1.25).toFixed(1) + 'rem',
  },
};

export default PopularJobSearch;
