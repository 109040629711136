import { Colors, FontFamily, Icons, base_font } from '@styles/graphite/styled';

const SectionCollapse = {
  Color: {
    iconColor: Colors.blue2,
    filterDropIcon: Colors.black,
  },
  BgColors: {
    secHeader: Colors.white,
  },
  BrdrColors: {
    secHeaderbrdr: Colors.shadowyBlack4,
    dropIconOutline: Colors.blue2,
  },
  FontSizes: {
    arrow: (base_font * 0.625).toFixed(1) + 'rem',
  },
  FontFamily: {
    arrow: FontFamily.fontAwesome,
  },
  FontWeight: {
    normal: 900,
    semibold: 600,
  },
  Icons: {
    arrow: Icons.chevrondown,
    arrowRotate: Icons.chevronup,
    chevrondownfill: Icons.chevrondownfill,
  },
};

export default SectionCollapse;
