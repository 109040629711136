import { FontFamily } from '@styles/graphite/styled';
import { Colors, FontWeight, base_font } from '@styles/sapphire/styled';

const JobAction = {
  Colors: {
    titleWrap: Colors.deepOcean,
    btnLgWrapper: Colors.white,
    btnLgWrapperHover: Colors.white,
    titleWrapText: Colors.azure,
  },
  BgColors: {
    jobsToggleBtn: Colors.black1,
    btnLgWrapper: Colors.orange1,
    btnLgWrapperHover: Colors.orange1,
  },
  FontSizes: {
    title: (base_font * 0.85).toFixed(1) + 'rem',
    titleWrapText: base_font.toFixed(1) + 'rem',
  },
  FontWeight: {
    medium: 500,
    title: FontWeight.semibold,
    jobAge: FontWeight.semibold,
    normal: 400,
    bold: 700,
    accoladeName: 500,
  },
  FontFamily: {
    titleWrap: FontFamily.fontFamilyRaleway,
    btnLgWrapper: null,
  },
  BdrColors: {
    titleWrap: Colors.blue2,
    linkListWrapper: Colors.shadowyBlack4,
    linkListContent: Colors.gray12,
    linkList: Colors.blue22,
    btnLgWrapper: null,
    btnLgWrapperHover: null,
  },
};

export default JobAction;
