import { Colors } from '@styles/base/styled';

const ResearchCompanyStyle = {
  Colors: {
    title: Colors.black,
  },
  BgColors: {
    wrapper: Colors.gray1,
  },
};

export default ResearchCompanyStyle;
