import { Colors } from '@styles/base/styled';

const FormRadioStyle = {
  Colors: {
    textareaDesc: Colors.gray1,
  },
  BgColors: {
    radioBg: Colors.white,
    radioBgChecked: Colors.black,
  },
  BdrColors: {
    inputWrap: Colors.shadowSharp,
    textarea: Colors.gray1,
    radioBgChecked: Colors.black,
    radioBgFocus: Colors.black,
  },
};

export default FormRadioStyle;
