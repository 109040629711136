import { Colors, FontFamily, FontSizes, Icons } from '@styles/graphite/styled';

const SavedSearches = {
  Colors: {
    searchcolordark: Colors.black,
    searchtimecolor: Colors.gray6,
    notifyiconcolor: Colors.blue2,
    gridshadow: Colors.shadowyBlack2,
    SuccessMsgWrap: Colors.green3,
    LinkBtn: Colors.blue1,
  },
  BdrColors: {
    jobsrchbotborder: Colors.blue12,
    jobsrchtopborder: Colors.blue1,
    searchcardborder: Colors.shadowyBlack4,
    widgetcardborder: Colors.shadowyBlack,
    SuccessMsgWrap: Colors.green4,
  },
  FontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
  },
  FontSizes: {
    clocktimefont: FontSizes.h5,
  },
  FontFamilies: {
    iconfonts: FontFamily.fontAwesome,
  },
  Icons: {
    clocklefticon: Icons.clockRotateLeft,
    tickicon: Icons.tickicon,
    circlecross: Icons.circlecross,
  },
  BgColors: {
    SuccessMsgWrap: Colors.green2,
  },
};
export default SavedSearches;
