import { Colors, FontSizes, base_font } from '@styles/base/styled';

const UnAuthPopupStyle = {
  BgColors: {
    modalinnerwrap: Colors.black,
    modalcontentbg: Colors.black,
    pointerswrapbg: Colors.white,
  },
  Colors: {
    listhead: Colors.black,
    quotedtextColor: Colors.gray1,
  },
  FontSizes: {
    detailContent: (base_font * 0.875).toFixed(1) + 'rem',
    quotedtextfs: FontSizes.h6,
    contentAuthor: (base_font * 0.875).toFixed(1) + 'rem',
  },
};

export default UnAuthPopupStyle;
