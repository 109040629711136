import { Colors, fontWeight } from '@styles/base/styled';
const FormCtrlStyle = {
  Colors: {
    input: Colors.black,
  },
  FontWeight: {
    label: fontWeight.bold,
  },
  BdrColors: {
    input: Colors.gray1,
    inputReq: Colors.black,
  },
};

export default FormCtrlStyle;
