import { device } from '@styles/device.styled';
import styled, { css } from 'styled-components';

interface FeaturedWrapProps {
  gridCol?: string;
  blog?: boolean;
  smallAside?: boolean;
  mobApplyGrid?: boolean;
  columnSpace?: string;
  isRCPortal?: boolean;
  isCategoryOpenDetailJobId?: boolean;
}

interface StyledDiv {
  fullwidth?: boolean;
  customWidth?: string;
  layout?: boolean;
  shadow?: boolean;
  smallAside?: boolean;
  padding?: boolean;
  isPrimaryContainer?: boolean;
  isRMCPage?: boolean;
  isRCJobsDetails?: boolean;
}

export const FeaturedWrap = styled.div<FeaturedWrapProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridCol ||
    (props.blog ? '58.33% 41.67%' : props.smallAside ? '75% 25%' : '1fr 1fr')};
  column-gap: ${({ columnSpace }) => columnSpace};

  .left,
  .right {
    padding: ${(props) => (props.blog ? '0 1.2rem' : '')};
  }

  ${device.mobileTab} {
    display: block;

    .left,
    .right {
      padding: 0;
      box-shadow: none;
    }
    display: ${(props) => props.mobApplyGrid && 'grid'};
  }
  ${({ isRCPortal }) =>
    css`
      margin: ${isRCPortal && '0 -1.5rem'};
    `}
`;

export const StyledContainerMd = styled.div<StyledDiv>`
  max-width: ${({ fullwidth, customWidth, isPrimaryContainer }) =>
    fullwidth ? null : customWidth ? customWidth : isPrimaryContainer ? '119rem' : '117rem'};
  width: ${({ fullwidth }) => (fullwidth ? null : '100%')};
  margin: ${({ fullwidth }) => (fullwidth ? null : '0 auto')};
  padding: ${({ fullwidth }) => (fullwidth ? null : '0 1.5rem')};
  scroll-margin-top: 16.5rem;

  ${device.mediumdesktopView} {
    max-width: ${({ fullwidth, isPrimaryContainer }) =>
    fullwidth ? null : isPrimaryContainer ? '129.5rem' : '132rem'};
  }
`;
export const LeftSec = styled.div<StyledDiv>`
  padding: ${({ padding, isRMCPage }) =>
    padding ? '0 1.2rem 1.5rem' : isRMCPage && '0 1.6rem 0 0'};
  ${({ theme: { LayoutStyle }, shadow }) =>
    LayoutStyle &&
    css`
      box-shadow: ${shadow &&
      `${LayoutStyle.Colors.leftSectionShadow} 0 0.2rem 0 0.2rem`};
    `};
  ${({ isRCJobsDetails }) =>
    isRCJobsDetails &&
    css`
      padding-top: 0;
      margin: 1.6rem 0 4rem;
    `}
`;

export const RightSection = styled.div<{ isRMCPage?: boolean }>`
  padding: ${({ isRMCPage }) => !isRMCPage && '0.8rem 0 1.6rem 1.6rem'};
`;

export const Title = styled.span`
  display: none;
`;

export const SkipLink = styled.a`
  color: #fff;
  background-color: #424242f2;
  padding: 0.8rem 2rem;
  font-size: 1.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #fff;
  z-index: 9999;
  top: 0;
  transition: transform 0.2s;
  text-align: left;
  overflow: hidden;
  left: 0;
  position: fixed;
  transform: translateY(-100%);
  cursor: pointer;
  text-decoration: none;
  &:focus,
  &:hover,
  &:active {
    transform: translateY(0%);
    outline: none;
    text-decoration: none;
    z-index: 11111;
    color: #fff;
  }
`;
export const ContentWrapper = styled.div<{ isRCPortal?: boolean }>`
${({ isRCPortal }) => isRCPortal && css`
${device.mobileLg}{
  display: flex;
flex-direction: column;
min-height: 100vh;
> footer{
  margin-top: auto;
}
}

`}

`