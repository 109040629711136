import { Colors, base_font } from '@styles/base/styled';

const IndvCompStyle = {
  BgColors: {
    alert: Colors.gray1,
    sliderWrap: Colors.white,
    rightSectionBg: Colors.shadowSharp,
  },
  Colors: {
    alert: Colors.black,
    Commitment: Colors.gray1,
    CommitmentLabel: Colors.gray2,
    textHighlight: Colors.black,
    AccoladeTxt: Colors.black,
  },
  BdrColors: {
    alert: Colors.gray1,
    sliderWrap: Colors.gray2,
    companyWrapper: Colors.gray2,
  },
  FontSizes: {
    HeadingNameSm: (base_font * 1.62).toFixed(1) + 'rem',
  },
  LineHeights: {
    benefitText: 1.2,
  },
};

export default IndvCompStyle;
