import { Colors, FontFamily, base_font } from '@styles/graphite/styled';

const CommonStyle = {
  BgColors: {
    CookieBannerWrap: Colors.white,
  },
  Colors: {
    CookieBannerWrap: Colors.black,
    BannerCloseBtn: Colors.black,
  },
  FontSizes: {
    CookieBannerWrap: (base_font * 0.75).toFixed(1) + 'rem',
    CookieBannerWrapSm: (base_font * 0.625).toFixed(1) + 'rem',
    DescriptionTxt: (base_font * 0.875).toFixed(1) + 'rem',
    BannerCloseBtn: (base_font * 1.15).toFixed(1) + 'rem',
    BannerCloseBtnSm: (base_font * 1.28).toFixed(1) + 'rem',
  },
  FontFamily: {
    BannerCloseBtn: FontFamily.fontAwesome,
  },
};

export default CommonStyle;
