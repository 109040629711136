import {
  base_font,
  Colors,
  FontFamily,
  FontWeight,
} from '@styles/graphite/styled';

const JobAction = {
  Colors: {
    titleWrap: Colors.deepOcean,
    btnLgWrapper: Colors.white,
    btnLgWrapperHover: Colors.white,
    titleWrapText: Colors.azure,
  },
  BgColors: {
    jobsToggleBtn: Colors.lightgreen1,
    btnLgWrapper: Colors.azure,
    btnLgWrapperHover: Colors.blue27,
    linkListContent: Colors.gray50,
  },
  FontSizes: {
    title: (base_font * 0.85).toFixed(1) + 'rem',
    titleWrapText: base_font.toFixed(1) + 'rem',
  },
  FontWeight: {
    medium: 500,
    title: FontWeight.semibold,
    jobAge: FontWeight.semibold,
    normal: 400,
    bold: 700,
    accoladeName: 500,
  },
  FontFamily: {
    titleWrap: FontFamily.fontFamilyRaleway,
    btnLgWrapper: null,
  },
  BdrColors: {
    titleWrap: Colors.deepOcean,
    linkListWrapper: Colors.gray50,
    linkListContent: Colors.gray50,
    linkList: Colors.blue22,
    btnLgWrapper: Colors.azure,
    btnLgWrapperHover: Colors.blue27,
  },
};

export default JobAction;
