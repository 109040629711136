import { Colors, FilterGrayScale } from '@styles/sapphire/styled';

const PltfrmCatgStyle = {
  Colors: {
    bannerWrap: Colors.white,
  },
  BgColors: {
    browseWrap: Colors.white,
  },
  BdrColors: {
    browseWrap: Colors.black,
  },
  BoxShadow: {
    browseWrap: Colors.gray1,
  },
  Filter: {
    wrapper: FilterGrayScale.inActive,
  },
};

export default PltfrmCatgStyle;
