import { Colors, base_font } from '@styles/sapphire/styled';

const QualityBannerStyle = {
  BgColors: {
    bannerWrap: Colors.blue2,
  },
  Colors: {
    bannerWrap: Colors.white,
    secHeading: Colors.white,
  },
  FontSizes: {
    secHeading: (base_font * 1.75).toFixed(1) + 'rem',
    qualityList: (base_font * 1.25).toFixed(1) + 'rem',
  },
};

export default QualityBannerStyle;
