import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #007cad;
  padding: 1.6rem 0;
  color: #fff;
  font-weight: 500;
  ${Mixin.AllCenter({ gap: '4.8rem' })};
  .large-width {
    padding: 0.6rem 2.4rem;
  }
  ${device.mobileLg} {
    flex-wrap: wrap;
    gap: 1.5rem;
  }
`;
