import { Colors } from '@styles/sapphire/styled';

const FormCtrlStyle = {
  Colors: {
    input: Colors.black,
  },
  BgColors: {
    radioBg: Colors.white,
    radioBgChecked: Colors.blue14,
  },
  BdrColors: {
    input: Colors.gray1,
    checkboxFocus: Colors.blue12,
    inputReq: Colors.black,
    inputWrap: Colors.shadowSharp,
    radioBgChecked: Colors.blue14,
    radioBgFocus: Colors.shadowyBlack5,
  },
};

export default FormCtrlStyle;
