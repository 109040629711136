import { Colors } from '@styles/base/styled';

const PltfrmDetailStyle = {
  Colors: {
    info: Colors.black,
  },
  BgColors: {
    wrapper: Colors.white,
  },
  BoxShadow: {
    wrapper: Colors.gray1,
  },
};

export default PltfrmDetailStyle;
