import { Colors } from '@styles/sapphire/styled';

const AdvanceJobStyle = {
  Colors: {
    colorDark: Colors.black,
    colorWhite: Colors.white,
    colorAlert: Colors.blue1,
  },
  BgColors: {
    bgAlert: Colors.blue1,
  },
  BrdrColors: {
    brdrAlert: Colors.blue1,
  },
  FontWeights: {
    medium: 500,
  },
};
export default AdvanceJobStyle;
