import { Colors, base_font } from '@styles/sapphire/styled';

const HeroBannerStyle = {
  BgColors: {
    section: Colors.blue8,
    imgWrap: Colors.blue2,
  },
  Colors: {
    textHighlight: Colors.orange1,
    imgHeading: Colors.white,
  },
  FontSizes: {
    heading: (base_font * 2.25).toFixed(1) + 'rem',
  },
};

export default HeroBannerStyle;
