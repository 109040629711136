import { base_font, Colors, FontFamily, FontWeight } from '@styles/graphite/styled';

const JobSearch = {
  Colors: {
    wrapper:Colors.stormGray,
    btnWrapper:Colors.black1,
    btnWrapperHover:Colors.deepOcean,
    btnWrapperActive:Colors.gray45
  },
  BgColors: {
    btnWrapper:Colors.white,
    btnWrapperActive: Colors.lightgreen,
    toogleBtn:Colors.lightgray,
    toogleBtnHover:Colors.white,
    accordionBtn:Colors.white,
    accordionBtnNot:Colors.gray46
  },
  FontSizes: {
    title: (base_font * 0.85).toFixed(1) + 'rem',
  },
  FontWeight: {
    medium: 500,
    title: FontWeight.semibold,
    jobAge: FontWeight.semibold,
    normal: 400,
    bold: 700,
    accoladeName: 500,
  },
  FontFamily:{
    btnWrapper:FontFamily.fontFamilyRaleway
  },
  BdrColors: {
    wrapper:Colors.gray3,
    btnWrapper:Colors.gray1,
    btnWrapperActive: Colors.lightgreen1,
    toogleBtn:Colors.azure,
    expandJobList:Colors.gray3,
    accordionBtn:Colors.azure
  },
};

export default JobSearch;
