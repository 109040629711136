import { Colors, base_font } from '@styles/sapphire/styled';

const ModalStyle = {
  Colors: {
    colorWhite: Colors.white,
    colorDark: Colors.black,
    CloseIcon: Colors.gray1,
    closeColor: Colors.shadowyBlack5,
  },
  FontSizes: {
    CloseIcon: (base_font * 1.375).toFixed(1) + 'rem',
    PopupcloseIcon: (base_font * 1.5).toFixed(1) + 'rem',
  },
  BdrColors: {
    ContentVal: Colors.shadowyBlack,
  },
  BgColors: {
    Overlay: Colors.shadowyLightest,
    ContentWrap: Colors.white,
    modalHeadBg: Colors.blue1,
    modalHeadBg2: Colors.blue2,
    modalHeadBgGradient: Colors.shadowLight,
    BottomDrawerContainer: Colors.white,
  },
};

export default ModalStyle;
