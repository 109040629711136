import { Colors, base_font, fontWeight } from '@styles/base/styled';

const PillsStyle = {
  Colors: {
    pills: Colors.white,
    pillsHover: Colors.white,
  },
  BgColors: {
    pills: Colors.black,
  },
  FontSizes: {
    pills: base_font + 'rem',
    pillsMob: base_font * 0.875 + 'rem',
  },
  FontWeight: {
    pills: fontWeight.medium,
  },
};

export default PillsStyle;
