import { Colors, base_font } from '@styles/base/styled';

const NewsBlogStyle = {
  BgColors: {
    newClipWrap: Colors.white,
  },
  Colors: {
    clipWrapper: Colors.gray2,
    clipHeading: Colors.black,
    newsClipShadow: Colors.shadowSharp,
  },
  BdrColors: {
    newClipWrap: Colors.gray2,
  },
  FontSizes: {
    clipHeading: (base_font * 1.125).toFixed(1) + 'rem',
  },
};

export default NewsBlogStyle;
