import { Colors, FontFamily, base_font } from '@styles/graphite/styled';

const HeaderSectionStyle = {
  Colors: {
    title: Colors.blue1,
    badge: Colors.white,
    CategoryHeading: Colors.black1,
  },
  FontSizes: {
    badge: base_font + 'rem',
    Heading: base_font * 1.625 + 'rem',
    CategoryHeading: (base_font * 1.25).toFixed(1) + 'rem',
    CategoryMobileHeading: (base_font * 1.125).toFixed(1) + 'rem',
    ViewMoreBtn: (base_font * 0.875).toFixed(1) + 'rem',
  },
  FontFamily: {
    CategoryHeading: FontFamily.fontFamilyOpensense,
  },
  BgColors: {
    badge: Colors.azure,
  },
  BdrColors: {
    wraperborder: Colors.gray15,
    Description: Colors.gray50,
  },
  LineHeights: {
    badge: base_font * 0.625 + 'rem',
  },
};

export default HeaderSectionStyle;
