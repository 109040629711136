import { Colors, base_font } from '@styles/base/styled';

const MobBannerStyle = {
  BgColors: {
    wrap: Colors.gray2,
  },
  Colors: {
    heading: Colors.black,
  },
  FontSizes: {
    heading: (base_font * 1.875).toFixed(1) + 'rem',
    para: (base_font * 1.125).toFixed(1) + 'rem',
  },
};

export default MobBannerStyle;
