import { Colors } from '@styles/graphite/styled';

const PaginationStyle = {
  Colors: {
    paginationLinkFocus:Colors.blue16,
    paginationLinkActive: Colors.white,
    paginationLinkHover: Colors.blue11,
    linkDisable: Colors.gray6,
  },
  BgColors: {
    paginationLinkActive: Colors.azure,
    paginationLinkHover: Colors.gray8,
  },
  BdrColors: {
    paginationLink: Colors.gray3,
    paginationLinkActive: Colors.azure,
    paginationLinkHover: Colors.gray8,
    paginationLinkFocus: Colors.blue11,
  }
};

export default PaginationStyle;
