import { Colors, base_font } from '@styles/sapphire/styled';

const CarouselStyle = {
  BgColors: {
    indicatorActive: Colors.gray19,
  },
  Colors: {
    button: Colors.gray19,
    buttonHover: Colors.black,
  },
  FontSizes: {
    button: (base_font * 1.25).toFixed(1) + 'rem',
  },
};

export default CarouselStyle;
