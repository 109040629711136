import { Colors, base_font } from '@styles/sapphire/styled';

const CompanyAwardsStyle = {
  BgColors: {
    awardsSection: Colors.white,
    seeAllLinkHover: Colors.blue2,
  },
  Colors: {
    brandLink: Colors.blue2,
    brandLinkHover: Colors.blue3,
    seeAllLink: Colors.blue2,
    seeAllLinkHover: Colors.white,
    awardsHeading: Colors.black,
  },
  BdrColors: {
    awardsSection: Colors.shadowyBlack,
    awardsList: Colors.gray3,
    seeAllLink: Colors.blue2,
  },
  FontSizes: {
    awardsHeading: base_font * 1.375 + 'rem',
    brandLink: base_font * 1.25 + 'rem',
  },
  FontWeight: {
    medium: 500,
  },
};

export default CompanyAwardsStyle;
