import { Colors, base_font } from '@styles/graphite/styled';
const GreatEmployer = {
  Colors: {
    rightArrow: Colors.blue1,
  },
  FontSizes: {
    secHeading: (base_font * 1.5).toFixed(1) + 'rem',
    rightArrow: (base_font * 1.75).toFixed(1) + 'rem',
  },
};

export default GreatEmployer;
