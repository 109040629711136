import {
  Colors,
  FontSizes,
  base_font,
  base_font_mb,
} from '@styles/base/styled';

const EmployersStyle = {
  BgColors: {
    bannerWrap: Colors.white,
    testimonialWrapper: Colors.black,
    listItem: Colors.white,
  },

  Colors: {
    bannerTitle: Colors.black,
    bannerDesc: Colors.black,
    title: Colors.white,
    itemList: Colors.black,
    star: Colors.white,
    secoTitle: Colors.black,
    secoContact: Colors.black,
    linkWrapper: Colors.black,
    loomeeTitle: Colors.gray1,
    icon: Colors.black,
    naamalTitle: Colors.gray1,
    naamalIcon: Colors.gray1,
    availabilty: Colors.black,
    teleLink: Colors.gray1,
    teleLinkIcon: Colors.black,
    needhelphead: Colors.black,
    programcordinator: Colors.black,
    subHeading: Colors.black,
    workwellicon: Colors.gray1,
    followUs: Colors.black,
    colTitle: Colors.black,
    arrowHover: Colors.white,
    arrowPrevNextBtn: Colors.gray1,
  },
  FontWeights: {
    otherTitle: 500,
    secoTitle: 600,
    item: 600,
    loomeeTitle: 600,
    naamalTitle: 500,
    naamalLink: 500,
    agncUsefulLinks: 500,
    contact: 500,
    contactTitle: 600,
    followUs: 500,
    colTitle: 600,
  },
  BrdColors: {
    leftTxtWrapper: Colors.gray1,
    listItem: Colors.gray2,
    borderedCard: Colors.gray1,
  },
  LineHeights: {
    otherTitle: 1.2,
    address: 1.2,
    secoTitle: 1.4,
    naamalTitle: 1.2,
    agncUsefulLinks: 1.1,
    contactTitle: 1.2,
  },
  Shadows: {
    listItem: Colors.gray1,
  },
  FontSizes: {
    bannerTitle: FontSizes.h1,
    bannerTitleSm: FontSizes.h2,
    title: FontSizes.h2,
    bannerDesc: base_font + 'rem',
    bannerDescSm: FontSizes.h6,
    description: base_font + 'rem',
    otherTitle: FontSizes.h3,
    otherTitleSm: FontSizes.h4,
    contact: base_font + 'rem',
    commonmobilefs: base_font_mb + 'rem',
    address: base_font + 'rem',
    addressSm: FontSizes.h6,
    itemList: base_font + 'rem',
    star: FontSizes.h3,
    swiperBtn: (base_font * 1.87).toFixed(1) + 'rem',
    secoTitle: base_font + 'rem',
    secoContact: base_font + 'rem',
    linkWrapper: base_font + 'rem',
    listItem: base_font + 'rem',
    loomeeContact: base_font + 'rem',
    loomeeContactSm: base_font_mb + 'rem',
    item: base_font + 'rem',
    chatIcon: base_font_mb + 'rem',
    loomeeTitle: FontSizes.h2,
    loomeeTitleSm: (base_font * 1.37).toFixed(1) + 'rem',
    icon: FontSizes.h4,
    naamalTitle: FontSizes.h2,
    naamalTitleSm: (base_font * 1.37).toFixed(1) + 'rem',
    naamalLink: (base_font * 1.31).toFixed(1) + 'rem',
    naamalIcon: FontSizes.h4,
    agncContactIcon: FontSizes.h6,
    agncUsefulLinks: (base_font * 1.07).toFixed(1) + 'rem',
    contactSm: FontSizes.h6,
    agncUsefulLinksSm: base_font + 'rem',
    contactTitle: FontSizes.h2,
    followUs: (base_font * 1.35).toFixed(1) + 'rem',
    followUsSm: (base_font * 1.37).toFixed(1) + 'rem',
    contactTitleSm: (base_font * 1.37).toFixed(1) + 'rem',
    facebook: (base_font * 2).toFixed(1) + 'rem',
    facebookSm: (base_font * 1.37).toFixed(1) + 'rem',
    columnWrapper: base_font + 'rem',
    columnWrapperSm: base_font_mb + 'rem',
    needhelphead: (base_font * 1.5).toFixed(1) + 'rem',
    programcordinator: base_font + 'rem',
    connectLink: FontSizes.h4,
    connectMsg: FontSizes.h4,
    connectMsgRural: FontSizes.h4,
  },
};

export default EmployersStyle;
