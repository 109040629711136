import { Colors, FontWeight, base_font } from '@styles/sapphire/styled';

const JobSection = {
  Colors: {
    jobsHeading:Colors.gray46,
    badgeWrapper:null
  },
  BgColors: {
    contentBoxWrapper:Colors.gray45,
    jobsHeading:Colors.blue2,
    jobListWrapper:Colors.white,
    badgeWrapper:Colors.shadowSharpGray
  },
  FontSizes: {
    title: (base_font * 0.85).toFixed(1) + 'rem',
  },
  FontWeight: {
    medium: 500,
    title: FontWeight.semibold,
    jobAge: FontWeight.semibold,
    normal: 400,
    bold: 700,
    accoladeName: 500,
  },
  FontFamily:{
    titleWrap:null
  },
  BdrColors: {
    contentBoxWrapper:Colors.shadowyBlack4,
    jobListWrapper:Colors.shadowyBlack4,
    jobList:Colors.shadowyBlack4,
    seeAllLink:Colors.gray1,
    arrowIconIsTrue:Colors.white,
    arrowIconIsFalse:Colors.blue2
  },
};


export default JobSection;
