import { Colors } from '@styles/sapphire/styled';

const RadialProgStyle = {
  BgColors: {
    circle: Colors.gray2,
    progCircle: Colors.orange2,
  },
};

export default RadialProgStyle;
