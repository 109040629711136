import { Colors, base_font } from '@styles/graphite/styled';

const BtnStyle = {
  Colors: {
    primaryBtn: Colors.white,
    secondaryBtn: Colors.blue2,
    tertiaryBtn: Colors.white,
    transparentBtn: Colors.blue2,
    lightBtn: Colors.black1,
    socialBtn: Colors.black1,
    secondaryHover: Colors.white,
    btnIcon: Colors.azure,
    smallBtn: Colors.blue1,
    smallBtnHover: Colors.white,
    quaternary: Colors.azure,
    infoBtn: Colors.black1,
  },
  BgColors: {
    primaryBtn: Colors.blue2,
    secondaryBtn: Colors.white,
    tertiaryBtn: Colors.azure,
    lightBtn: Colors.white,
    socialBtn: Colors.white,
    primaryHover: Colors.blue1,
    secondaryHover: Colors.black3,
    smallBtnHover: Colors.blue1,
    quaternaryBtn: Colors.white,
    quaternaryHover: Colors.azure,
    infoBtn: Colors.gray26,
  },
  BdrColors: {
    primaryBtn: Colors.blue2,
    secondaryBtn: Colors.blue2,
    tertiaryBtn: Colors.azure,
    smallBtn: Colors.blue1,
    quaternary: Colors.azure,
    lightBtn: Colors.gray1,
    socialBtn: Colors.black1,
    infoBtn: Colors.gray26,
  },
  FontSizes: {
    btnXsmall: (base_font * 0.75).toFixed(1) + 'rem',
    btnSmall: (base_font * 0.875).toFixed(1) + 'rem',
    btn: base_font + 'rem',
    btnLarge: (base_font * 1.25).toFixed(1) + 'rem',
  },
};

export default BtnStyle;
