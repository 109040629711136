import { readCookie } from '@components/common';
import {
  ApiVersion,
  TIMEOUT_DURATION,
  TWENTY_FOUR_HOUR_CACHE,
} from '@components/common/constant';
import { ApiEndPoint } from '@components/shared.types';
import { boldLoggerServerSide, logErrorServer } from 'API/ApiHelpers';
import { getApiUrl, isValidJSON } from 'helper';
import cache from 'memory-cache';

interface APIResponse<T> {
  statusCode: number;
  data: T;
  error: string;
  message: string;
}

type BlobResponse<T> = {
  statusCode: number;
  configData: T;
  error: string;
  message: string;
};

type ReqHeader = {
  method?: string;
  credentials: 'include';
  body?: string;
  headers: {
    'Content-Type'?: string;
    Accept: string;
    cookie?: string;
  };
  signal?: AbortSignal;
};

class APIRequest<T> {
  private blobBaseUrl: string =
    (process.env.NEXT_PUBLIC_BLOB_URL as string) || '';

  private static instance: APIRequest<any>;

  private constructor() {}

  public static getInstance<T>(): APIRequest<T> {
    if (!APIRequest.instance) {
      APIRequest.instance = new APIRequest<T>();
    }
    return APIRequest.instance as APIRequest<T>;
  }

  async getConfig<U>(
    url: string,
    hasBlobURL: boolean = false
  ): Promise<BlobResponse<U>> {
    try {
      let apiURL = `${this.blobBaseUrl}${url}`;
      if (hasBlobURL) {
        apiURL = url;
      }
      const cachedResponse = cache.get(apiURL);
      if (cachedResponse) {
        return {
          statusCode: 200,
          configData: cachedResponse.data as U,
          error: '',
          message: '',
        };
      } else {
        const response = await fetch(apiURL);
        let data = await response.json();
        cache.put(apiURL, {
          apiURL,
          data,
          timestamp: 2 * 60 * 60 * 1000,
        });
        return {
          statusCode: response.status,
          configData: data as U,
          error: '',
          message: '',
        };
      }
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      console.error(`Error fetching data from ${url}:`, errorMessage);
      throw new Error('Failed to fetch data from BLOB' + url + errorMessage);
    }
  }
  async getWithServerCache<U>(
    url: ApiEndPoint,
    cookieHeader?: string,
    apiBasePath: string = '',
    version: ApiVersion = ApiVersion.V1,
    headers?: any
  ): Promise<APIResponse<U>> {
    const apiUrl = getApiUrl(apiBasePath, url, version);

    if (typeof window === 'undefined') {
      boldLoggerServerSide(apiUrl, cookieHeader || '');
    }

    try {
      //force cache delete
      if (readCookie('purgeServeCache', cookieHeader)) {
        cache.del;
      }
      const cachedResponse = cache.get(apiUrl);
      if (cachedResponse) {
        return {
          statusCode: 200,
          data: cachedResponse,
          error: '',
          message: '',
        };
      } else {
        const headersObj: ReqHeader = {
          method: 'GET',
          credentials: 'include',
          headers: {
            'True-Client-IP': headers ? headers['true-client-ip'] || '' : '',
            Accept: 'application/json',
            cookie: cookieHeader || '',
            ...(headers ? headers : {}),
            ...(typeof window === 'undefined' && {
              signal: AbortSignal.timeout(TIMEOUT_DURATION),
            }),
          },
        };

        const response = await fetch(apiUrl, headersObj);
        if (!response.ok) {
          logErrorServer(
            `FXJ RMC_JOBS Client error: Status Code: ${response.status}`,
            `Failed to fetch data from api: ${apiUrl} [API STATUS]: ${response.statusText}`,
            true,
            cookieHeader
          );
          return {
            statusCode: 500,
            data: null as U,
            error: 'Failed to parse response data as JSON',
            message: '',
          };
        }
        const responseData = await response.json();
        cache.put(apiUrl, responseData, TWENTY_FOUR_HOUR_CACHE);
        return {
          statusCode: response.status,
          data: responseData as U,
          error: responseData.error,
          message: responseData.message,
        };
      }
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      logErrorServer(
        `FXJ RMC_JOBS Client error:`,
        `Failed to fetch data from api: ${apiUrl}, Error: ${errorMessage}`,
        true,
        cookieHeader
      );
      return {
        statusCode: 500,
        data: null as U,
        error: 'Failed to parse response data as JSON',
        message: '',
      };
    }
  }

  async get<U>(
    url: ApiEndPoint,
    cookieHeader?: string,
    apiBasePath: string = '',
    version: ApiVersion = ApiVersion.V1,
    headers?: any
  ): Promise<APIResponse<U>> {
    const apiUrl = getApiUrl(apiBasePath, url, version);

    if (typeof window === 'undefined') {
      boldLoggerServerSide(apiUrl, cookieHeader || '');
    }

    try {
      const headersObj: ReqHeader = {
        method: 'GET',
        credentials: 'include',
        headers: {
          'True-Client-IP': headers ? headers['true-client-ip'] || '' : '',
          Accept: 'application/json',
          cookie: cookieHeader || '',
          ...(headers ? headers : {}),
          ...(typeof window === 'undefined' && {
            signal: AbortSignal.timeout(TIMEOUT_DURATION),
          }),
        },
      };
      const response = await fetch(apiUrl, headersObj);
      if (!response.ok) {
        logErrorServer(
          `FXJ RMC_JOBS Client error: Status Code: ${response.status}`,
          `Failed to fetch data from api: ${apiUrl} [API STATUS]: ${response.statusText}`,
          true,
          cookieHeader
        );
      }
      const responseData = await response.json();
      return {
        statusCode: response.status,
        data: responseData as U,
        error: responseData.error,
        message: responseData.message,
      };
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      logErrorServer(
        `FXJ RMC_JOBS Client error:`,
        `Failed to fetch data from api: ${apiUrl}, Error: ${errorMessage}`,
        true,
        cookieHeader
      );
      return {
        statusCode: 500,
        data: null as U,
        error: 'Failed to parse response data as JSON',
        message: '',
      };
    }
  }

  async post<U>(
    url: string,
    data?: T,
    apiBasePath: string = '',
    version: ApiVersion = ApiVersion.V1
  ): Promise<APIResponse<U>> {
    const apiUrl = getApiUrl(apiBasePath, url as ApiEndPoint, version);

    try {
      const headersObj: ReqHeader = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
        ...(typeof window === 'undefined' && {
          signal: AbortSignal.timeout(TIMEOUT_DURATION),
        }),
      };
      const response = await fetch(apiUrl, headersObj);
      const responseData = await response.json();
      return {
        statusCode: responseData.statusCode,
        data: responseData as U,
        error: responseData.error,
        message: responseData.message,
      };
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      console.error(`Error posting data to ${apiUrl}:`, errorMessage);
      throw new Error('Failed to post data to API' + url + errorMessage);
    }
  }

  async postWithCache<U>(
    url: string,
    data?: T,
    apiBasePath: string = '',
    version: ApiVersion = ApiVersion.V1,
    clearCookie: boolean = false
  ): Promise<APIResponse<U>> {
    try {
      const cacheKey = `${url}-${JSON.stringify(data)}`;
      const cachedResponse = cache.get(cacheKey) as APIResponse<U> | undefined;
      if (cachedResponse && !clearCookie) {
        return cachedResponse;
      }

      const headersObj: ReqHeader = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
        ...(typeof window === 'undefined' && {
          signal: AbortSignal.timeout(TIMEOUT_DURATION),
        }),
      };
      const response = await fetch(
        getApiUrl(apiBasePath, url as ApiEndPoint, version),
        headersObj
      );
      const responseData = await response.json();

      const apiResponse: APIResponse<U> = {
        statusCode: responseData.statusCode,
        data: responseData as U,
        error: responseData.error,
        message: responseData.message,
      };

      cache.put(cacheKey, apiResponse, 24 * 60 * 60 * 1000); // Cache for 1 day (24 hours)
      return apiResponse;
    } catch (error) {
      let errorMessage = error;
      if (isValidJSON(error)) {
        errorMessage = JSON.stringify(error);
      }
      console.error(
        `Error posting data to ${apiBasePath}${url}:`,
        errorMessage
      );
      throw new Error('Failed to post data to API' + url + errorMessage);
    }
  }
}

export default APIRequest;
