import {
  createCookie,
  isCurrentPageInList,
  loadJs,
  readCookie,
  setPaymentAndSubscriptionStatus,
} from '@components/common';
import {
  Debuger_Script_URLS,
  ECOM_WIDGET_URLS,
} from '@components/common/constant';
import ErrorBoundary from '@components/errorBoundry';
import { GlobalContextProvider } from '@components/globalContext';
import { Layout } from '@components/layout';
import { ThemeWrapper } from '@license-admin/boldfjcomponents';
import { CreateTheme } from '@styles/styles';
import { getUserID } from 'helper/experiments/experimentActions';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import CookieBanner from './cookie-banner';
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    TS: any;
    APP: any;
    sendBannerEvent: any;
  }
}

function App({ Component, pageProps }: any) {
  const router = useRouter();
  const [cookiebanner, setCookieBanner] = useState(true);
  const [themeType, setThemeType] = useState(
    pageProps.themeType || process.env.NEXT_PUBLIC_THEME
  );
  useEffect(() => {
    pageProps.deviceType === 'mobile' &&
      isCurrentPageInList(router, Debuger_Script_URLS) &&
      loadJs(`https://cdn.debugbear.com/Ey5GNaVl1Fiv.js`);

    isCurrentPageInList(router, ECOM_WIDGET_URLS) &&
      loadJs(`/payment/scripts/rmc/sellPrefetchScript.js`);

    getCookieBannerStatus();
  }, []);

  const closeCookieBanner = () => {
    getCookieBannerStatus();
  };

  function getCookieBannerStatus() {
    if (
      readCookie('show_cookie_consent_banner') !== '' &&
      readCookie('show_cookie_consent_banner') === 'false'
    ) {
      setCookieBanner(false);
    }
  }
  const theme = CreateTheme(themeType);
  useEffect(() => {
    setPaymentAndSubscriptionStatus();
    const userID = getUserID();
    // if (userID !== null) {
    //   getUsersAllExperimentAndUpdateCookie(userID, document.cookie);
    // }
    // Cleanup event listener on component unmount
  }, []);

  const handleThemeChange = (event: React.FormEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value !== 'default') {
      setThemeType(event.currentTarget.value.toLowerCase());
      createCookie('theme', event.currentTarget.value.toLowerCase());
    }
  };

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <ThemeWrapper setThemeType={themeType} getTheme={theme.MainTheme}>
        {pageProps.deviceType !== 'mobile' && <theme.BaseFont />}
        <theme.GlobalCSS cookie_banner={cookiebanner} />
        <GlobalContextProvider>
          <ErrorBoundary>
            <Layout {...pageProps} handleThemeChange={handleThemeChange}>
              <Component {...pageProps} />
              <CookieBanner closeCookieBanner={closeCookieBanner} />
            </Layout>
          </ErrorBoundary>
        </GlobalContextProvider>
      </ThemeWrapper>
      <>
        <Script
          src={'/blobcontent/rmc/externals/app.js'}
          id="app-js"
          strategy="afterInteractive"
        />
      </>
    </>
  );
}

export default App;
