import { Colors, base_font } from '@styles/base/styled';

const BtnStyle = {
  Colors: {
    primaryBtn: Colors.white,
    secondaryBtn: Colors.black,
    tertiaryBtn: Colors.black,
    transparentBtn: Colors.black,
    infoBtn: Colors.white,
    secondaryHover: Colors.white,
    btnIcon: Colors.black,
    smallBtnHover: Colors.white,
    lightBtn: Colors.white,
    socialBtn: Colors.white,
  },
  BgColors: {
    primaryBtn: Colors.black,
    secondaryBtn: Colors.white,
    tertiaryBtn: Colors.gray2,
    infoBtn: Colors.gray1,
    secondaryHover: Colors.gray1,
    smallBtnHover: Colors.black,
    lightBtn: Colors.black,
    socialBtn: Colors.black,
  },
  BdrColors: {
    primaryBtn: Colors.black,
    secondaryBtn: Colors.gray1,
    tertiaryBtn: Colors.gray2,
    smallBtn: Colors.gray1,
    lightBtn: Colors.gray1,
    socialBtn: Colors.black,
  },
  FontSizes: {
    btnXsmall: (base_font * 0.75).toFixed(1) + 'rem',
    btnSmall: (base_font * 0.875).toFixed(1) + 'rem',
    btn: base_font + 'rem',
    btnLarge: (base_font * 1.25).toFixed(1) + 'rem',
    smallBtn: (base_font * 0.875).toFixed(1) + 'rem',
  },
};

export default BtnStyle;
