import { Colors, base_font } from '@styles/base/styled';
const SignUpStyle = {
  BgColors: {
    topWrapper: Colors.black,
    tabWrapperAfter: Colors.gray1,
    tabWrapperbg: Colors.gray1,
    formWrap: Colors.white,
    tabCell: Colors.white,
  },
  Colors: {
    swiperTextColor: Colors.black,
    swiperPrimaryTextColor: Colors.white,
    topImgColor: Colors.black,
    tabCellTextColor: Colors.black,
    tabCellTextActiveColor: Colors.white,
    getStartedTextColor: Colors.white,
    highlightTextColor: Colors.white,
    textDescriptionColor: Colors.black,
    highlightColor: Colors.black,
    headingTextColor: Colors.white,
    preFillUser: Colors.black,
    faQuoteColor: Colors.black,
  },
  FontSizes: {
    headingText: base_font * 2 + 'rem',
    mobileheadingText: base_font * 1.5 + 'rem',
    textDescription: base_font * 1.5 + 'rem',
    getStarted: base_font * 1.1 + 'rem',
    mobiletabCell: base_font * 0.9 + 'rem',
    swiperText: base_font * 1.3 + 'rem',
    swiperTextMb: (base_font * 1.1).toFixed(2) + 'rem',
  },
  BdrColors: {
    topWrapperBorderColor: Colors.black,
  },
  LineHeights: {
    headingText: 1.2,
    mobheadingText: 1.2,
    textDescription: 1.5,
    getStarted: 1.2,
    tabCell: 1.5,
    swiperText: 1.4,
  },
  FontWeight: {
    textPrimaryWeight: 500,
    swiperTextWeight: 300,
    tabCellActiveWeight: 700,
    getStartedWeight: 500,
    boldTextWeight: 500,
    highlightTextWeight: 500,
    headingTextWeight: 500,
    preFillUserWeight: 400,
    consentLink: 600,
  },
};

export default SignUpStyle;
