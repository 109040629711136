import { Colors, FontSizes } from '@styles/base/styled';

const CompanyHiringStyle = {
  Colors: {
    buttonHover: Colors.white,
    title: Colors.black,
    titleHover: Colors.black,
  },
  BdrColors: {
    button: Colors.black,
    card: Colors.gray1,
  },
  BgColors: {
    button: Colors.white,
    buttonHover: Colors.black,
  },
  FontSizes: {
    btnPrimary: FontSizes.h5,
  },
  FontWeight: {
    medium: 500,
  },
};

export default CompanyHiringStyle;
