import { Colors, base_font } from '@styles/base/styled';

const FooterStyle = {
  Colors: {
    link: Colors.black,
    heading: Colors.black,
  },
  BgColors: {
    footer: Colors.gray2,
  },
  BdrColors: {
    mbCol: Colors.gray1,
    linkFocus: Colors.black,
  },
  FontSizes: {
    link: base_font * 0.875 + 'rem',
    copyRight: base_font * 0.875 + 'rem',
    socialIcons: base_font * 2 + 'rem',
    heading: base_font + 'rem',
    StyledP: base_font + 'rem',
  },
  LineHeights: {
    link: 1.5,
    copyRight: 1.5,
    heading: 1.5,
    socialIcons: 1,
  },
};

export default FooterStyle;
