import { Colors, base_font } from "@styles/base/styled";

const CompanyCtaPopupStyle = {
  Colors: {
    highlightText: Colors.gray1,
    descText: Colors.black,
  },
  FontSizes: {
    descText: (base_font * 1.75).toFixed(1) + 'rem',
  },
  FontWeight: {
    descText: 500,
  },
  LineHeight: {
    descText: 1.2,
    imageDesc: 1.2,
  },
};

export default CompanyCtaPopupStyle;
