import { css } from 'styled-components';
import { device } from './device.styled';

interface MixinVal {
  direction?: string;
  wrap?: string;
  align?: string;
  justify?: string;
  gap?: string;
}

interface PositionVal {
  position?: string;
  value?: string;
}
interface GridProps {
  gridCount?: number;
  columnGap?: string;
  rowGap?: string;
  alignItems?: string;
  gridDimension?: string;
  mobDimension?: string;
}
interface EllipsisProps {
  row?: any;
}
interface CustomScrollProps {
  customColor?: string;
}
export const Mixin = {
  AllCenter: ({ direction, wrap, align, justify, gap }: MixinVal = {}) => css`
    display: flex;
    flex-flow: ${direction} ${wrap};
    align-items: ${align || 'center'};
    justify-content: ${justify || 'center'};
    gap: ${gap || '0.4rem'};
  `,

  HorizontalCenter: ({ direction, wrap, justify, gap }: MixinVal = {}) => css`
    display: flex;
    gap: ${gap || '0'};
    flex-flow: ${direction} ${wrap};
    justify-content: ${justify || 'center'};
  `,
  VerticalCenter: ({ direction, wrap, align, gap }: MixinVal = {}) => css`
    display: flex;
    gap: ${gap || '0'};
    flex-flow: ${direction} ${wrap};
    align-items: ${align || 'center'};
  `,

  Position: ({ position, value }: PositionVal = {}) => css`
    position: ${position || 'absolute'};
    inset: ${value || 0};
  `,
  GridLayout: ({
    gridCount,
    gridDimension,
    columnGap,
    rowGap,
    alignItems,
    mobDimension,
  }: GridProps = {}) => css`
    display: grid;
    grid-template-columns: ${gridDimension
      ? gridDimension
      : gridCount
      ? `repeat(${gridCount},1fr)`
      : 'repeat(2, 1fr)'};
    align-items: ${alignItems || null};
    gap: ${rowGap || '0.8rem'} ${columnGap || '0.8rem'};
    ${device.mobileLg} {
      row-gap: ${rowGap || '0.8rem'};
      grid-template-columns: ${mobDimension ? mobDimension : '100%'};
    }
  `,
  Ellipsis: ({ row }: EllipsisProps = {}) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${row || 3};
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  CustomScroll: ({ customColor }: CustomScrollProps = {}) => css`
    &::-webkit-scrollbar {
      width: 0.8rem;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.5rem #fff;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background: ${customColor || '#c1c1c1'};
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #aaa;
    }
  `,
};
export default Mixin;
