import {
  Colors,
  FontFamily,
  Icons,
  LineHeight,
  base_font,
} from '@styles/graphite/styled';

const CatLinkStyle = {
  Colors: {
    colorDark: Colors.black,
    linkHover: Colors.blue3,
    headColor: Colors.blue1,
  },
  BgColors: {
    list: Colors.white,
    secWrapperMob: Colors.gray17,
  },
  BdrColors: {
    wrapper: Colors.gray2,
  },
  FontFamily: {
    linkIcon: FontFamily.fontAwesome,
  },
  FontWeight: {
    normal: 400,
    medium: 500,
  },
  FontSizes: {
    linkIcon: base_font * 0.875 + 'rem',
  },
  Icons: {
    linkIcon: Icons.angleright,
  },
  lineHeight: {
    link: LineHeight.xl,
  },
};

export default CatLinkStyle;
