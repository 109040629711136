import { Colors, base_font } from '@styles/base/styled';

const FormControlStyle = {
  Colors: {
    DarkColor: Colors.black,
    ErrorMsgWrap: Colors.black,
    Select: Colors.black,
  },
  BdrColors: {
    select: Colors.black,
    ErrorMsgWrap: Colors.black,
  },
  BgColors: {
    ErrorMsgWrap: Colors.gray1,
  },
  FontSizes: {
    bottomTxt: (base_font * 0.875).toFixed(1) + 'rem',
  },
};

export default FormControlStyle;
