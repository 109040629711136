import { Colors, base_font } from '@styles/base/styled';

const CompRedesign = {
  Colors: {
    arrow: Colors.black,
    collapseHead: Colors.black,
    compName: Colors.black,
    compTitle: Colors.black,
    storyContent: Colors.black,
    authName: Colors.black,
    hiredAt: Colors.black,
    compDetail: Colors.black,
    bannerHeading: Colors.white,
  },
  BgColors: {
    collapseCont: Colors.black,
    compDivision: Colors.black,
    compList: Colors.white,
    followCTRAWrap: Colors.black,
    successStoriesWrap: Colors.gray1,
    successStoriesWrapActive: Colors.black,
  },
  BdrColors: {
    collpaseCont: Colors.gray1,
    compHireWrap: Colors.gray1,
    compList: Colors.black,
    articleList: Colors.gray2,
  },
  FontSizes: {
    arrow: base_font + 'rem',
    collapseHead: base_font + 'rem',
    compExName: (base_font * 1.125).toFixed(1) + 'rem',
    articleLink: (base_font * 1.125).toFixed(1) + 'rem',
    compName: (base_font * 0.875).toFixed(1) + 'rem',
    compDetail: (base_font * 0.75).toFixed(1) + 'rem',
  },

  FontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    dark: 800,
  },
  BoxShadow: {
    articleList: Colors.gray1,
  },
};

export default CompRedesign;
