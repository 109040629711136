import { Colors, FilterGrayScale } from '@styles/sapphire/styled';

const HeadBannerStyle = {
  Colors: {
    bannerWrap: Colors.white,
  },
  BgColors: {
    wrapper: Colors.gray2,
    wrapperbg: Colors.blue4,
  },
  BdrColors: {
    wrapper: Colors.blue1,
  },
  Filter: {
    wrapper: FilterGrayScale.inActive,
  },
};

export default HeadBannerStyle;
