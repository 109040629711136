import { Colors, base_font, fontWeight, lineHeight } from '@styles/base/styled';

const JobPltfrmStyle = {
  lineHeight: {
    info: lineHeight.lg,
  },
  FontWeight: {
    platform: fontWeight.semibold,
    normal: fontWeight.normal,
  },
  BdrColors: {
    tileCard: Colors.gray2,
  },
  FontSizes: {
    linkIcon: base_font * 0.875 + 'rem',
  },
};

export default JobPltfrmStyle;
