import { base_font, Colors } from '@styles/sapphire/styled';

const JobSavedSearchStyle = {
  FontSizes: {
    EditBtn: (base_font * 1.125).toFixed(1) + 'rem', //1.8rem
    CardDel: (base_font * 1.125).toFixed(1) + 'rem', //1.8rem
  },
  Colors: {
    EditBtn: Colors.blue2,
    SuccessMsgWrap: Colors.green3,
    checkicon: Colors.blue2,
    DateTextWrap: Colors.gray6,
  },
  BdrColors: {
    SuccessMsgWrap: Colors.green2,
    CardItem: Colors.shadowyBlack,
  },
  BgColors: {
    SuccessMsgWrap: Colors.green2,
    CardItem: Colors.white,
  },
};

export default JobSavedSearchStyle;
