import { Colors, base_font } from '@styles/graphite/styled';

const ApplicationStyle = {
  Colors: {
    jobType: Colors.red4,
    applyDate: Colors.blue2,
    trashCan: Colors.blue2,
    stickyIcon: Colors.shadowyBlack3,
    stickySelected: Colors.blue2,
    appDate: Colors.gray6,
    deleteIcon: Colors.gray10,
    notehead: Colors.blue1,
    ToastLink: Colors.blue19,
    EmptyStateWrap: Colors.gray6,
    toast: Colors.blue13,
  },
  BgColors: {
    applicationWrap: Colors.white,
    toast: Colors.blue5,
    checkListWrap: Colors.white,
    notesWrap: Colors.blue10,
  },
  BdrColors: {
    borderSec: Colors.gray2,
    dropableListFocus: Colors.blue2,
    applicationWrap: Colors.gray2,
    checkListWrap: Colors.gray2,
    checkListTop: Colors.blue1,
    borderNotes: Colors.gray3,
    AppApplyGuideImg: Colors.gray3,
    EmptyStateWrapper: Colors.gray17,
    EmptyStateWrapperDash: Colors.gray3,
  },
  FontSizes: {
    trashIcon: (base_font * 0.875).toFixed(1) + 'rem',
    deleteIcon: (base_font * 1.563).toFixed(1) + 'rem',
    stickyIcon: (base_font * 2).toFixed(1) + 'rem',
    timeIcon: base_font * 0.875 + 'rem',
    editText: (base_font * 0.875).toFixed(1) + 'rem',
    appliedOnText: (base_font * 0.875).toFixed(1) + 'rem',
  },
  FontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
  },
};

export default ApplicationStyle;
