import { Colors, base_font } from '@styles/sapphire/styled';

const NewsBlogStyle = {
  BgColors: {
    newClipWrap: Colors.white,
    clipWrapper: Colors.blue2,
  },
  Colors: {
    clipHeading: Colors.black,
    newsClipShadow: Colors.shadowyBlack6,
  },
  BdrColors: {
    newClipWrap: Colors.gray20,
  },
  FontSizes: {
    clipHeading: (base_font * 1.125).toFixed(1) + 'rem',
  },
};

export default NewsBlogStyle;
