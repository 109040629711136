import {
  Colors,
  FontSizes,
  FontWeight,
  base_font,
} from '@styles/graphite/styled';

const JobInfoFooterStyle = {
  Colors: {
    colorDark: Colors.black1,
    reportLab: Colors.red4,
    reportedLab: Colors.black,
    deleteIcon: Colors.gray10,
    label: Colors.blue2,
    JobNoteActionBtn: Colors.black1,
    NoteHeadWrap: Colors.gray29,
  },
  BdrColors: {
    jobActWrap: Colors.gray1,
    textAreaWrap: Colors.gray1,
    BtnWrapper: Colors.gray1,
    popupFooter: Colors.gray1,
    JobDetailsButton: Colors.gray1,
  },
  BgColors: {
    deleteNoteWrap: Colors.shadowyWhite,
  },
  FontSizes: {
    textArea: FontSizes.h5,
    deleteIcon: FontSizes.h3,
    applybtn: (base_font * 0.875).toFixed(1) + 'rem',
  },
  FontWeight: {
    icon: FontWeight.medium,
    deleteIcon: FontWeight.normal,
    notesHeader: FontWeight.medium,
    semibold: 600,
  }
};

export default JobInfoFooterStyle;
