import { Colors } from '@styles/base/styled';
const NewProfileFormStyle = {
  Colors: {
    inputPlaceHolder: Colors.gray2,
  },
  BgColors: {
    FormWrapper: Colors.gray1,
  },
  BdrColors: {
    FormWrapper: Colors.black,
  },
};
export default NewProfileFormStyle;
