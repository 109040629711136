import { Colors } from '@styles/sapphire/styled';

const LoginStyle = {
  BgColors: {
    btn: Colors.black,
    form: Colors.gray2,
    bg: Colors.white,
    OtherOptWrap: Colors.black,
    popupbg: Colors.blue4,
    signInWrapBg: Colors.blue8,
    innerViewWrapBg: Colors.white,
    socialButton: null,
  },
  Colors: {
    OtherOptWrap: Colors.black,
    SocialLoginBtn: Colors.black,
    loginWrapShadow: Colors.shadowSharp,
    socialButton: null,
  },
  BdrColors: {
    OtherOptWrap: Colors.gray37,
    SocialLoginBtn: Colors.black,
    popupbdr: Colors.blue2,
    LoginWrap: Colors.gray15,
    innerViewShadow: Colors.shadowyBlack5,
    innerViewBrdr: Colors.shadowyBlack4,
  },
};

export default LoginStyle;
