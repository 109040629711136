import { Colors, base_font } from '@styles/base/styled';

const ExitPopUpStyle = {
  BgColors: {
    popUpColor: Colors.white,
    listItemsBg: Colors.shadowSharp,
  },

  Colors: {
    headTitleColor: Colors.white,
    topReasons: Colors.black,
    mainHead: Colors.black,
    textWarning: Colors.black,
    whyTextColor: Colors.white,
    ReasonsItemText: Colors.black,
    ReasonsItemNumber: Colors.black,
  },

  FontSizes: {
    reasonsItemText: base_font * 1 + 'rem',
    reasonsItemNumber: base_font * 3.5 + 'rem',
    whyText: base_font * 1.25 + 'rem',
    whyTextIcon: (base_font * 0.75).toFixed(1) + 'rem',
    mainHeadText: base_font * 1.5 + 'rem',
    headTitleText: (base_font * 1.4).toFixed(1) + 'rem',
  },
};

export default ExitPopUpStyle;
