import { Colors, base_font, base_font_mb } from '@styles/base/styled';

const FormGroupStyle = {
  BgColors: {
    label: Colors.gray2,
    ErrorMsgWrap: Colors.gray2,
  },
  Colors: {
    input: Colors.black,
    passwordMask: Colors.gray1,
    floatingLabel: Colors.gray1,
    animateLabel: Colors.black,
    errortext: Colors.black,
    astrix: Colors.black,
    ErrorMsgWrap: Colors.black,
    labelColor: Colors.black,
  },
  FontSizes: {
    typeTwoError: base_font_mb + 'rem',
    passwordMaskText: base_font_mb + 'rem',
    floatingLabel: base_font_mb + 'rem',
    animateLabel: base_font * 0.625 + 'rem',
  },
  BdrColors: {
    label: Colors.black,
    input: Colors.gray1,
    checkboxFocus: Colors.gray2,
    inputReq: Colors.black,
    ErrorMsgWrap: Colors.gray1,
  },
  BdrRadius: {
    input: '0.4rem;',
  }
};

export default FormGroupStyle;
