import { Colors, base_font } from '@styles/sapphire/styled';

const TestimonialStyle = {
  BgColors: {
    card: Colors.blue8,
    cardHighlight: Colors.blue2,
    thumbnail: Colors.white,
  },
  Colors: {
    quote: Colors.blue1,
    quoteHighlight: Colors.white,
    cardHighlight: Colors.white,
    cardShadow: Colors.shadowyBlack7,
  },
  FontSizes: {
    quote: (base_font * 2.25).toFixed(1) + 'rem',
  },
};

export default TestimonialStyle;
