import { Colors, base_font } from '@styles/base/styled';

const ModalStyle = {
  Colors: {
    colorWhite: Colors.white,
    colorDark: Colors.black,
    CloseIcon: Colors.gray1,
    BackIcon: Colors.gray1,
    closeColor: Colors.gray2,
  },
  FontSizes: {
    BackBtnIcon: (base_font * 0.75).toFixed(1) + 'rem',
    CloseIcon: (base_font * 1.375).toFixed(1) + 'rem',
    PopupcloseIcon: (base_font * 1.5).toFixed(1) + 'rem',
  },
  BdrColors: {
    Content: Colors.shadowyBlack,
    ContentTop: Colors.black,
    PopupButtonAction: Colors.gray1,
  },
  BgColors: {
    Overlay: Colors.overlay,
    BottomDrawerContainer: Colors.white,
    PopupButtonAction: Colors.gray2,
    modalHeadBg: Colors.black,
    modalHeadBg2: Colors.gray2,
    modalHeadBgGradient: Colors.shadowSharp,
  },
};

export default ModalStyle;
