import { Colors, FontWeight, base_font } from '@styles/sapphire/styled';

const JobSearch = {
  Colors: {
    wrapper:Colors.black,
    btnWrapper:Colors.black1,
    btnWrapperActive:Colors.black1
  },
  BgColors: {
    btnWrapper:Colors.white,
    btnWrapperActive: Colors.black1,
    toogleBtn:Colors.gray3,
    toogleBtnHover:Colors.black1,
    accordionBtn:Colors.white,
    accordionBtnNot:Colors.black1
  },
  FontSizes: {
    title: (base_font * 0.85).toFixed(1) + 'rem',
  },
  FontWeight: {
    medium: 500,
    title: FontWeight.semibold,
    jobAge: FontWeight.semibold,
    normal: 400,
    bold: 700,
    accoladeName: 500,
  },
  BdrColors: {
    wrapper:Colors.shadowyBlack3,
    btnWrapper:Colors.gray3,
    btnWrapperActive: Colors.black1,
    toogleBtn:Colors.black1,
    expandJobList:Colors.gray3,
    accordionBtn:Colors.gray3
  },
};

export default JobSearch;
