import { device } from '@styles/device.styled';
import { createGlobalStyle } from 'styled-components';
import {
  Colors,
  FilterGrayScale,
  FontSizes,
  base_font,
  base_font_mb,
  blob_path,
} from './styled';

const Sapphire_Global = createGlobalStyle<{ cookie_banner?: boolean }>`
    *{
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%;
    }

    html,body{
        scroll-behavior: smooth;
        
    }

    body, button, input, optgroup, select, textarea {
      font-family: 'Open Sans',Raleway, Arial, sans-serif;
    }

    body,
    select{
        margin: 0;
        width: 100%;
        font-size: ${base_font + 'rem'};
        line-height: 1.5;
        font-weight: 400;
        ${device.tabletMd} {
          font-size: ${base_font_mb + 'rem'};
         }
    }

    input[type='text'],
    input[type='password']{
      margin: 0;
      width: 100%;
      font-size: ${base_font + 'rem'};
      line-height: 1.5;
      font-weight: 400;
        
    }

    input, select {
        background-color: ${Colors.white};
        border-radius: 0;

        &:focus{
          outline: 0.2rem solid ${Colors.blue2};
          outline-offset: -0.2rem;
        }
    }

    input::-ms-reveal, input::-ms-clear {
      display: none;
    }

    input[type='checkbox']{
      accent-color: ${Colors.blue2};
      
      &:focus{
        outline-offset: 0.2rem;
      }
    }

    p{
        margin: 0 0 1.2rem;
    }

    img{
        filter: grayscale(${FilterGrayScale.inActive});
        max-width: 100%;
        height: auto;
        vertical-align: middle;
    }

    strong{
        font-weight: 600;
    }

    a{
      color: ${Colors.blue2};
      text-decoration: none;

      &:hover{
        color: ${Colors.blue3};
        text-decoration: underline;
      }

      &:focus{
          outline: 0.2rem dashed ${Colors.blue2};
          outline-offset: 0.2rem;
           text-decoration: underline;
        }
    }

    button{
      background: transparent;
      border: none;
      cursor: pointer;
      
      &:focus{
          outline: 0.2rem dashed ${Colors.blue2};
          outline-offset: 0.2rem;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
        color: ${Colors.blue1};
        font-weight: 500;
        line-height: 1.2;
        margin: 0 0 0.8rem;
    }

    h1{
      font-size: ${FontSizes.h1};
    }

    h2{
      font-size: ${FontSizes.h2};
    }

    h3{
      font-size: ${FontSizes.h3};
    }

    h4{
      font-size: ${FontSizes.h4};
    }

    h5{
      font-size: ${FontSizes.h5};
    }

    h6{
      font-size: ${FontSizes.h6};
    }

    .modal-open {
      overflow: hidden;
    }

    .iconFacebook {
      color: ${Colors.facebook};

      &:hover{
        color: ${Colors.facebook};
      }
    }

    .iconLinkedIn {
      color: ${Colors.linkedin};

      &:hover{
        color: ${Colors.linkedin};
      }
    }

    .iconTwitter {
      color: ${Colors.twitter};

      &:hover{
        color: ${Colors.twitter};
      }
    }

    .iconYouTube {
      color: ${Colors.youtube};

      &:hover{
        color: ${Colors.youtube};
      }
    }

    .iconPinterest {
      color: ${Colors.pinterest};

      &:hover{
        color: ${Colors.pinterest};
      }
    }

    .iconInstagram {
      color: ${Colors.instagram};

      &:hover{
        color: ${Colors.instagram};
      }
    }
    
    .iconTikTok {
      color: ${Colors.tiktok};
      
      &:hover{
        color: ${Colors.tiktok};
      }
    }

    .iconReddit {
      color: ${Colors.reddit};
      
      &:hover{
        color: ${Colors.reddit};
      }
    }

    small{
      font-size: ${(base_font * 0.875).toFixed(1) + 'rem'};
    }

    .fa-square-twitter.visible {
      background: ${
        Colors.black
      } center center url(${blob_path}/icon-twitter-x.svg) no-repeat;
      background-size: 55%;
      border-radius: 20%;
      margin-top: 0.2rem;
      height: 2.9rem;
      width: 2.9rem;

      &::before{
        content: '';
      }
    }

    .fa-square-telegram.visible {
      background: ${
        Colors.blue15
      } center center url(${blob_path}/icons/telegram.svg) no-repeat;
      background-size: 55%;
      border-radius: 20%;
      margin-top: 0.2rem;
      height: 2.9rem;
      width: 2.7rem;

      &::before{
        content: '';
      }
      
    }

    .fa-tiktok.visible {
      background: bottom center url(${blob_path}/icn-tiktok.png) no-repeat;
      background-size: contain;
      margin-top: 0.2rem;
      height: 2.9rem;
      width: 2.9rem;
      
      &::before{
        content: '';
      }
    }

    body .embeddedServiceHelpButton .helpButton .uiButton {
      background-color: ${Colors.blue2};
      font-family: "Open Sans",sans-serif;
      border-radius: 3.5rem;
      min-width: 15.7rem;
    }

    body .embeddedServiceHelpButton .helpButton{
      right: calc(calc(100% - 95rem)/2);
      bottom: ${({ cookie_banner }) => (cookie_banner ? '6.1rem' : '0.8rem')};
    }

    body .embeddedServiceHelpButton.embeddedServiceBottomTabBar .helpButton{
      ${device.mobileLg}{
        right: 0;
        bottom: ${({ cookie_banner }) => (cookie_banner ? '8.1rem' : '0.8rem')};
        padding: 0 2rem;
      }
    }
`;

export default Sapphire_Global;
