import ApiClientManager from './ApiClientManager';

const apiClientManager = ApiClientManager.getInstance();

const ApiStore = {
  FJ: apiClientManager.createClient(process.env.NEXT_PUBLIC_FJ_URL),
  JOBS: apiClientManager.createClient(process.env.NEXT_PUBLIC_JOBS_URL_RJ),
  EB: apiClientManager.createClient(process.env.NEXT_PUBLIC_EB_URL),
};

export default ApiStore;
