import { base_font, Colors, FontFamily, FontWeight } from '@styles/graphite/styled';

const JobSection = {
  Colors: {
    jobsHeading:Colors.gray46,
    badgeWrapper:Colors.black1
  },
  BgColors: {
    contentBoxWrapper:Colors.gray47,
    jobsHeading:Colors.azure,
    jobListWrapper:Colors.white,
    badgeWrapper:Colors.lightgreen
  },
  FontSizes: {
    title: (base_font * 0.85).toFixed(1) + 'rem',
  },
  FontWeight: {
    medium: 500,
    title: FontWeight.semibold,
    jobAge: FontWeight.semibold,
    normal: 400,
    bold: 700,
    accoladeName: 500,
  },
  FontFamily:{
    titleWrap:FontFamily.fontFamilyRaleway
  },
  BdrColors: {
    contentBoxWrapper:Colors.shadowyBlack4,
    jobListWrapper:Colors.shadowyBlack4,
    jobList:Colors.shadowyBlack4,
    seeAllLink:Colors.deepOcean,
    arrowIconIsTrue:Colors.white,
    arrowIconIsFalse:Colors.azure
  },
};

export default JobSection;
