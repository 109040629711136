import { base_font, Colors } from '@styles/base/styled';

const JobSavedSearchStyle = {
  FontSizes: {
    EditBtn: (base_font * 1.125).toFixed(1) + 'rem', //1.8rem
    CardDel: (base_font * 1.125).toFixed(1) + 'rem', //1.8rem
  },
  Colors: {
    EditBtn: Colors.black,
    SuccessMsgWrap: Colors.black,
    checkicon: Colors.black,
    DateTextWrap: Colors.gray1,
  },
  BdrColors: {
    SuccessMsgWrap: Colors.gray1,
    CardItem: Colors.shadowyBlack,
  },
  BgColors: {
    SuccessMsgWrap: Colors.gray2,
    CardItem: Colors.white,
  },
};

export default JobSavedSearchStyle;
