import { Colors } from '@styles/base/styled';

const CompanyGuideStyle = {
  Colors: {
    btnWrap: Colors.white,
    colorDark: Colors.black,
  },
};

export default CompanyGuideStyle;
