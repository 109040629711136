import { Colors, base_font } from '@styles/graphite/styled';

const UpComingEventStyle = {
  Colors: {
    type: Colors.blue1,
  },
  FontSizes: {
    type: (base_font * 0.875).toFixed(1) + 'rem',
  },
};

export default UpComingEventStyle;
