import { Colors } from '@styles/sapphire/styled';

const NewProfileFormStyle = {
  Colors: {
    inputPlaceHolder: Colors.gray33,
  },
  BgColors: {
    FormWrapper: Colors.blue4,
  },
  BdrColors: {
    FormWrapper: Colors.blue2,
  },
};
export default NewProfileFormStyle;
