import { Colors, FontFamily, Icons, base_font } from '@styles/graphite/styled';

const TopCatgStyle = {
  Colors: {
    plusIcon: Colors.blue2,
    moreCatInfo: Colors.black,
    btnEdit: Colors.black,
    headColor: Colors.black,
    btnEditOutline: Colors.blue2,
    moreCatLink: Colors.black,
    editLink: Colors.blue2,
  },
  BgColors: {
    btnEdit: Colors.white,
  },
  BdrColors: {
    listItem: Colors.gray2,
    btnEdit: Colors.gray2,
  },
  FontFamily: {
    icon: FontFamily.fontAwesome,
  },
  FontSizes: {
    icon: (base_font * 0.87).toFixed(1) + 'rem',
    editSquareIcon: (base_font * 0.6875).toFixed(1) + 'rem',
  },
  Icons: {
    angleRight: Icons.angleright,
    plusIcon: Icons.pluscircle,
    editIcon: Icons.edit,
  },
};

export default TopCatgStyle;
