import {
  Colors,
  FilterGrayScale,
  FontSizes,
  base_font,
} from '@styles/base/styled';

const HeroSecStyle = {
  Colors: {
    colorWhite: Colors.white,
    heading: Colors.white,
  },
  BdrColors: {
    wrapper: Colors.black,
  },
  BgColors: {
    wrapper: Colors.gray1,
  },
  FontSizes: {
    heading: base_font * 2 + 'rem',
    headingResearch: (base_font * 1.75).toFixed(1) + 'rem',
    headingMob: (base_font * 1.36).toFixed(1) + 'rem',
    mobileButton: FontSizes.h6,
    desktopButton: FontSizes.h3,
    headingMobile: (base_font * 1.48).toFixed(1) + 'rem',
  },
  Filter: {
    wrapper: FilterGrayScale.active,
  },
};

export default HeroSecStyle;
