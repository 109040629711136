import { Colors, base_font } from '@styles/sapphire/styled';

const BannerStyle = {
    Colors: {
        title: Colors.blue1,
        subTitle: Colors.black,
    },
    BgColors: {
        bannerWrap: Colors.gray21,
    },
    FontSizes: {
        title: (base_font * 1.75).toFixed(1) + 'rem',
        subTitle: (base_font * 1.25).toFixed(1) + 'rem',
        titleMobile: (base_font * 1.3).toFixed(1) + 'rem',
        subTitleMobile: (base_font * 1.1).toFixed(1) + 'rem',
    },
    FontWeight: {
        subTitle: 500,
    },
    LineHeight: {
        title: 1.4,
        subTitle: 1.5,
        joinBtn: 1.5,
    },
};

export default BannerStyle;
