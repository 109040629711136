import { Colors, base_font } from '@styles/graphite/styled';

const PltfrmTypeStyle = {
  Colors: {
    type: Colors.blue1,
  },
  FontSizes: {
    infoMob: base_font * 0.875 + 'rem',
  },
};

export default PltfrmTypeStyle;
