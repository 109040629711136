import {
  Colors,
  FontFamily,
  FontWeight,
  base_font,
  base_font_mb,
} from '@styles/graphite/styled';

const JobDetailsStyle = {
  Colors: {
    btn: Colors.black,
    linkActive: Colors.blue2,
    link: Colors.gray6,
    linkDark: Colors.black,
    linkBtn: Colors.white,
    list: Colors.black,
    icon: Colors.lightgreen1,
    iconblue: Colors.lightgreen1,
    approvedHover: Colors.blue3,
    approvedText: Colors.gray25,
    jobDate: Colors.blue22,
    aboutTitle: Colors.blue1,
    previousText: Colors.blue2,
    verifiedInfo: Colors.blue1,
    authour: Colors.black,
    textHighlight: Colors.azure,
    bannerQuote: Colors.blue1,
    jobTime: Colors.blue22,
    compName: Colors.black1,
  },
  BgColors: {
    wrapper: Colors.white,
    previousText: Colors.white,
    linkBtn: Colors.azure,
    card: Colors.gray40,
    stickyWrap: Colors.white,
    quoteBox: Colors.blue18,
    cardButton: Colors.azure,
    cardHoverButton: Colors.blue27,
    CardContent: Colors.gray47,
  },
  BdrColors: {
    wrapper: Colors.gray50,
    tabsWrapper: Colors.gray2,
    link: Colors.blue2,
    imageCard: Colors.gray24,
    wrapperActive: Colors.blue2,
    card: Colors.gray50,
    cardButton: Colors.azure,
  },
  BoxShadow: {
    card: Colors.gray52,
    cardHover: Colors.gray15,
    previousText: Colors.black5,
    stickyWrapShadow: Colors.gray15,
  },
  FontSize: {
    linkBtn: base_font + 'rem',
    approvedText: (base_font * 0.685).toFixed(1) + 'rem',
    tabfontsm: base_font + 'rem',
    tabfontmd: (base_font * 1.1875).toFixed(1) + 'rem',
    tabfontlg: (base_font * 1.5).toFixed(1) + 'rem',
    jobDate: (base_font * 0.75).toFixed(1) + 'rem',
    jobTitleSm: (base_font * 1.5).toFixed(1) + 'rem',
    aboutTitle: (base_font * 1.12).toFixed(1) + 'rem',
    unlockBannerTitleLg: (base_font * 2).toFixed(1) + 'rem',
    unlockBannerTitleSm: (base_font * 1.25).toFixed(1) + 'rem',
    BannerListItems: (base_font * 0.875).toFixed(1) + 'rem',
    previousText: base_font_mb + 'rem',
    verfiedInfo: (base_font * 0.75).toFixed(1) + 'rem',
    authour: (base_font * 0.75).toFixed(1) + 'rem',
    bannerQuote: (base_font * 0.875).toFixed(1) + 'rem',
    jobTime: (base_font * 0.875).toFixed(1) + 'rem',
    saveJobBtn: (base_font * 0.875).toFixed(1) + 'rem',
    unlockBannerTitle: (base_font * 1.625).toFixed(1) + 'rem',
    stickyButtonWrapper: (base_font * 1.125).toFixed(1) + 'rem',
    stickyButtonNewWrapper: (base_font * 1.125).toFixed(1) + 'rem',
    jobTitleNew: (base_font * 1.5).toFixed(1) + 'rem',
    compName: (base_font * 1.125).toFixed(1) + 'rem',
    saveBtn: base_font + 'rem',
    jobDetailTitlelg: (base_font * 1.5).toFixed(1) + 'rem',
    jobDetailSubTitlelg: (base_font * 1.125).toFixed(1) + 'rem',
    cardButton: base_font + 'rem',
  },
  FontWeight: {
    linkBtn: FontWeight.medium,
    icon: FontWeight.strong,
    verifiedInfo: 600,
    highlightText: 600,
    quote: 500,
  },
  FontFamily: {
    jobTitleNew: FontFamily.fontFamilyOpensense,
    compName: FontFamily.fontFamilyOpensense,
    jobDetailTitlelg: FontFamily.fontFamilyOpensense,
    jobDetailSubTitlelg: FontFamily.fontFamilyOpensense,
  },
  Sizes: {
    saveJobBtn: null,
  },
  Spacing: { saveJobBtn: null },
  GrayScale: {
    CardWrapper: null,
  },
};

export default JobDetailsStyle;
