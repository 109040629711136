import { Colors } from '@styles/base/styled';

const RadialProgStyle = {
  BgColors: {
    circle: Colors.gray2,
    progCircle: Colors.black,
  },
};

export default RadialProgStyle;
