import ApiClient from './ApiClient';

class ApiClientManager {
  private static instance: ApiClientManager | null = null;
  private clients: Record<string, ApiClient> = {};

  private constructor() {}

  public static getInstance(): ApiClientManager {
    if (!ApiClientManager.instance) {
      ApiClientManager.instance = new ApiClientManager();
    }
    return ApiClientManager.instance;
  }

  public getClient(baseUrl: string): ApiClient {
    return this.clients[baseUrl];
  }

  public createClient(baseUrl: string = ''): ApiClient {
    if (!this.clients[baseUrl]) {
      this.clients[baseUrl] = new ApiClient(baseUrl);
    }
    return this.clients[baseUrl];
  }
}

export default ApiClientManager;
