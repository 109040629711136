import { Colors } from '@styles/graphite/styled';

const ProgressStyle = {
  BgColors: {
    ProgressPrimary: Colors.blue2,
    ProgressSecondary: Colors.orange2,
    ProgressSec: Colors.blue8,
    ProgressSecondry: Colors.gray8,
  },
  Colors: {
    LabelWrap: Colors.blue22,
  },
};
export default ProgressStyle;
