import { Colors } from '@styles/base/styled';

const SimilarJobsStyle = {
  BgColors: {
    jobType: Colors.gray2,
  },
  BdrColors: {
    jobsWrap: Colors.shadowyBlack3,
  },
};

export default SimilarJobsStyle;
