import { Colors, base_font } from '@styles/sapphire/styled';

const BannerSection = {
  Colors: {
    BannerHeading: Colors.white,
    BannerSubHeading: Colors.white,
  },
  BgColors: {
    BannerWrapper: Colors.blue1,
    ClientImageWrapper: Colors.blackEclipseGray,
  },
  BdrColors: {},
  FontSizes: {
    BannerHeading: (base_font * 3.25).toFixed(1) + 'rem',
    BannerSubHeading: (base_font * 1.35).toFixed(1) + 'rem',
  },
};

export default BannerSection;
