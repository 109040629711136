import { Colors, FontFamily, Icons, base_font } from '@styles/graphite/styled';

const MultiFilterStyle = {
  Colors: {
    itemSelected: Colors.white,
    listHover: Colors.white,
    list: Colors.black,
    listHighlight: Colors.white,
    listSelected: Colors.black,
  },
  BgColors: {
    button: Colors.white,
    dropList: Colors.white,
    listSelected: Colors.gray2,
    listHighlight: Colors.blue17,
    list: Colors.white,
    listHover: Colors.blue17,
    itemSelected: Colors.blue2,
  },
  BdrColors: {
    button: Colors.gray7,
    dropList: Colors.gray7,
    dropDownFocus: Colors.blue2,
  },
  FontSizes: {
    heading: base_font + 'rem',
    crossIcon: (base_font * 0.625).toFixed(1) + 'rem',
    caretIcon: (base_font * 0.69).toFixed(1) + 'rem',
  },

  FontFamily: {
    iconFont: FontFamily.fontAwesome,
  },
  Icons: {
    chevrondown: Icons.chevrondown,
  },
};

export default MultiFilterStyle;
