import { Colors } from '@styles/sapphire/styled';

const SkillsWidgetStyle = {
  BrdrColor: {
    wrapperBrdr: Colors.gray2,
    wrapperTopBrdr: Colors.blue1,
  },
  FontWeights: {
    semibold: 600,
  },
};
export default SkillsWidgetStyle;
