export const Colors = {
  white: '#ffffff',
  black: '#000000',
  black1: '#212529',
  black8: '#555555',
  gray1: '#aaaaaa',
  gray2: '#eeeeee',
  gray3: '#cccccc',
  gray4: '#BFBFBF',
  gray5: '#f8f9fa',
  blue22: '#343A40',
  shadowyBlack: 'rgba(0, 0, 0,0.13)',
  shadowyBlack2: 'rgba(0, 0, 0, 0.03)',
  shadowyBlack3: 'rgba(0, 0, 0, 0.125)',
  shadowSharp: 'rgba(0, 0, 0, 0.25)',
  overlay: 'rgba(0, 0, 0,0.4)',
};

export const base_font = 1.6;

export const base_font_mb = 1.4;

export const FontSizes = {
  h1: (base_font * 1.75).toFixed(1) + 'rem', //2.8rem
  h2: (base_font * 1.5).toFixed(1) + 'rem', //2.4rem
  h3: (base_font * 1.25).toFixed(1) + 'rem', //2rem
  h4: (base_font * 1.125).toFixed(1) + 'rem', //1.8rem
  h5: base_font + 'rem', //1.6rem
  h6: (base_font * 0.875).toFixed(1) + 'rem', //1.4rem
  p: base_font + 'rem', //1.6rem
};

export const lineHeight = {
  normal: 1.2,
  md: base_font * 1.25 + 'rem',
  lg: base_font * 1.5 + 'rem',
  xl: 1.5,
  xxl: 2,
};

export const fontWeight = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  strong: 900,
};

export const BorderRadius = {
  none: '0',
  small: '0.4rem',
  medium: '0.8rem',
  large: '1.2rem',
  full: '50vmax',
};

export const FontFamily = {
  fontAwesome: '"Font Awesome 6 Pro"',
  fontFamilyRaleway:'Raleway,"Open Sans", Arial, sans-serif',
  fontFamilyOpensense: '"Open Sans",Raleway, Arial, sans-serif',
};
export const Icons = {
  suitcase: '"\f0f2"',
  rectanglelist: '"\f022"',
  newspaper: '"\f1ea"',
  location: '"\f3c5"',
  flag: '"\f11d"',
  world: '"\f57e"',
  pluscircle: '"\f055"',
  edit: '"\f044"',
  angleright: '"\f105"',
  angleup: '"\f106"',
  angledown: '"\f107"',
  angleLeft: '"\f104"',
  chevrondown: '"\f078"',
  chevronup: '"\f077"',
  chevronright: '"\f10d"',
  chevrondownfill: '"\f0d7"',
  clockRotateLeft: "'\f1da'",
  tickicon: "'\f00c'",
  circlecross: "'\f05c'",
};

export const FilterGrayScale = {
  active: 1,
  inActive: 0,
};

export const blob_path = `${process.env.NEXT_PUBLIC_CONTENT_URL}/images`;
