import { Colors } from '@styles/base/styled';

const ProgressStyle = {
  BgColors: {
    ProgressPrimary: Colors.black,
    ProgressSecondary: Colors.black,
    ProgressSec: Colors.gray2,
  },
  Colors: {
    LabelWrap: Colors.black,
  },
};
export default ProgressStyle;
