import { Colors, base_font } from '@styles/base/styled';

const BannerSection = {
  Colors: {},
  BgColors: {
    BannerWrapper: Colors.gray1,
    ClientImageWrapper: Colors.gray1,
  },
  BdrColors: {},
  FontSizes: {
    BannerHeading: (base_font * 3.25).toFixed(1) + 'rem',
    BannerSubHeading: (base_font * 1.35).toFixed(1) + 'rem',
  },
};

export default BannerSection;
