import { Colors, base_font } from '@styles/graphite/styled';

const CompActivityStyle = {
  Colors: {
    circle: Colors.blue1,
    activityDate: Colors.blue1,
    count: Colors.azure,
  },
  BgColors: {
    banner: Colors.blue8,
    circle: Colors.white,
    compDetails: Colors.white,
    cardShadow: Colors.gray15,
  },
  BdrColors: {
    banner: Colors.azure,
    card: Colors.gray2,
    cardFooter: Colors.gray2,
    compDetails: Colors.gray2,
    borderLeft: Colors.gray1,
  },
  FontSizes: {
    countTypeMob: base_font * 0.9375 + 'rem',
    count: base_font * 2.5 + 'rem',
    countMob: base_font * 1.875 + 'rem',
    headerIcon: base_font * 1.25 + 'rem',
    compName: base_font * 1.25 + 'rem',
    activityDate: base_font * 1.25 + 'rem',
    activityLink: base_font * 1.25 + 'rem',
  },
};

export default CompActivityStyle;
