import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const LinksWrapper = styled.div`
  ${Mixin.AllCenter()}
  padding: 2rem 3.1rem 1.1rem;
`;
export const FooterLinks = styled.a`
  position: relative;
  font-size: 1.6rem;
  line-height: 1.25;
  font-weight: 600;
  padding: 0 2.5rem;
  :after {
    content: '';
    position: absolute;
    height: 1.5rem;
    width: 0.1rem;
    top: 2px;
    bottom: 0;
    background: #004f6d;
    right: 0;
  }
  &:last-child:after {
    display: none;
  }
`;
export const FooterContent = styled.div`
  ${Mixin.GridLayout({ gridDimension: '1fr 2fr 1fr', alignItems: 'center' })}
  padding: 0.8rem 3.1rem;
  background-color: #004f6d;
  color: #fff;
`;
export const FooterText = styled.span<{ isCopyrightText?: boolean }>`
  position: relative;
  color: #fff;
  margin-bottom: 1.1rem;
  font-size: 1.05rem;
  line-height: 1.1;
  font-weight: 500;
  :last-child {
    margin-bottom: 0;
  }
  ${({ isCopyrightText }) =>
    isCopyrightText &&
    css`
      padding: 0 0.5rem;
      margin-bottom: 0;
      :after {
        content: '';
        position: absolute;
        height: 3.9rem;
        width: 0.1rem;
        top: -1.4rem;
        bottom: 0;
        background: #f8fafb;
        right: -6.5rem;
      }
      &:last-child:after {
        display: none;
      }
    `}
`;
export const LeftContent = styled.span`
  ${Mixin.HorizontalCenter({ direction: 'column', align: 'flex-start' })}
  max-width: 22rem;
`;
export const RightsServiceWrap = styled.span`
  display: inline-flex;
  align-items: center;
  column-gap: 13rem;
  justify-content: center;
  flex-wrap: nowrap;
`;
