import { base_font, Colors, FontFamily, fontWeight } from '@styles/base/styled';

const JobAction = {
  Colors: {
    titleWrap: Colors.gray1,
    btnLgWrapper: Colors.white,
    btnLgWrapperHover: Colors.white,
    TitleWrapText: Colors.black1,
  },
  BgColors: {
    jobsToggleBtn: Colors.black1,
    btnLgWrapper: Colors.black1,
    btnLgWrapperHover: Colors.black1,
  },
  FontSizes: {
    title: (base_font * 0.85).toFixed(1) + 'rem',
  },
  FontWeight: {
    medium: 500,
    title: fontWeight.semibold,
    jobAge: fontWeight.semibold,
    normal: 400,
    bold: 700,
    accoladeName: 500,
  },
  FontFamily: {
    titleWrap: FontFamily.fontFamilyRaleway,
    btnLgWrapper: FontFamily.fontFamilyOpensense,
  },
  BdrColors: {
    titleWrap: Colors.gray1,
    linkListWrapper: Colors.shadowyBlack3,
    linkListContent: Colors.black1,
    linkList: Colors.blue22,
    btnLgWrapper: Colors.black1,
    btnLgWrapperHover: Colors.black1,
  },
};

export default JobAction;
