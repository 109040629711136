import { Colors, base_font } from '@styles/graphite/styled';

const IndvCompStyle = {
  BgColors: {
    alert: Colors.blue5,
    sliderWrap: Colors.white,
    rightSectionBg: Colors.shadowLight,
  },
  Colors: {
    alert: Colors.blue13,
    Commitment: Colors.gray1,
    CommitmentLabel: Colors.gray6,
    textHighlight: Colors.azure,
    AccoladeTxt: Colors.blue22,
  },
  FontSizes: {
    HeadingNameSm: (base_font * 1.25).toFixed(1) + 'rem',
  },
  BdrColors: {
    alert: Colors.blue6,
    sliderWrap: Colors.gray30,
    companyWrapper: Colors.gray3,
  },
  LineHeights: {
    benefitText: 1.2,
  },
};

export default IndvCompStyle;
