import { Colors } from '@styles/base/styled';

const LoginStyle = {
  BgColors: {
    btn: Colors.black,
    form: Colors.gray2,
    bg: Colors.white,
    OtherOptWrap: Colors.black,
    popupbg: Colors.gray1,
    signInWrapBg: Colors.gray1,
    innerViewWrapBg: Colors.white,
    socialButton: Colors.white,
  },
  Colors: {
    OtherOptWrap: Colors.black,
    SocialLoginBtn: Colors.black,
    loginWrapShadow: Colors.shadowSharp,
    socialButton: Colors.black,
  },
  BdrColors: {
    OtherOptWrap: Colors.gray2,
    SocialLoginBtn: Colors.black,
    popupbdr: Colors.gray2,
    LoginWrap: Colors.gray1,
    innerViewShadow: Colors.shadowSharp,
    innerViewBrdr: Colors.shadowyBlack3,
  },
};

export default LoginStyle;
