import {
  Colors,
  FontFamily,
  Icons,
  base_font,
  fontWeight,
  lineHeight,
} from '@styles/base/styled';

const IndPlatformStyle = {
  Colors: {
    subHeadIcon: Colors.black,
  },
  BgColors: {
    container: Colors.gray2,
    aboutWrapper: Colors.white,
  },
  BdrColors: {
    aboutWrapper: Colors.gray1,
  },
  FontSizes: {
    infoTitle: base_font * 1.125 + 'rem',
    subHeading: base_font * 1.375 + 'rem',
    subHeadIcon: base_font * 1.75 + 'rem',
  },
  fontWeight: {
    mainHeading: fontWeight.semibold,
    subHeading: fontWeight.semibold,
    backtoLink: fontWeight.normal,
  },
  lineHeight: {
    para: lineHeight.lg,
  },
  FontFamily: {
    backtoLink: FontFamily.fontAwesome,
  },
  Icons: {
    backtoLink: Icons.angleright,
  },
};

export default IndPlatformStyle;
