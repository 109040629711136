import { Colors, base_font } from '@styles/sapphire/styled';
const SignUpStyle = {
  BgColors: {
    topWrapper: Colors.blue8,
    tabWrapperAfter: Colors.gray25,
    tabWrapperbg: Colors.blue20,
    formWrap: Colors.white,
    tabCell: Colors.white,
  },
  Colors: {
    swiperTextColor: Colors.black,
    swiperPrimaryTextColor: Colors.blue1,
    topImgColor: Colors.orange1,
    tabCellTextColor: Colors.black,
    tabCellTextActiveColor: Colors.blue2,
    getStartedTextColor: Colors.blue1,
    highlightTextColor: Colors.blue1,
    textDescriptionColor: Colors.black,
    highlightColor: Colors.orange1,
    headingTextColor: Colors.blue1,
    preFillUser: Colors.red4,
    faQuoteColor: Colors.red4,
  },
  FontSizes: {
    headingText: base_font * 2 + 'rem',
    mobileheadingText: base_font * 1.5 + 'rem',
    textDescription: base_font * 1.5 + 'rem',
    getStarted: (base_font * 1.15).toFixed(2) + 'rem',
    getStartedSm: (base_font * 1.07).toFixed(2) + 'rem',
    mobiletabCell: base_font * 0.9 + 'rem',
    swiperText: base_font * 1.3 + 'rem',
    swiperTextMb: (base_font * 1.1).toFixed(2) + 'rem',
  },
  BdrColors: {
    topWrapperBorderColor: Colors.orange2,
  },
  LineHeights: {
    headingText: 1.2,
    mobheadingText: 1.2,
    textDescription: 1.5,
    getStarted: 1.2,
    tabCell: 1.5,
    swiperText: 1.4,
  },
  FontWeight: {
    textPrimaryWeight: 500,
    swiperTextWeight: 300,
    tabCellActiveWeight: 700,
    getStartedWeight: 500,
    boldTextWeight: 500,
    highlightTextWeight: 500,
    headingTextWeight: 500,
    preFillUserWeight: 400,
    consentLink: 600,
  },
};

export default SignUpStyle;
