import { Colors, base_font } from '@styles/base/styled';

const CompanyAwardsStyle = {
  BgColors: {
    awardsSection: Colors.white,
    seeAllLinkHover: Colors.black,
  },
  Colors: {
    brandLink: Colors.gray1,
    brandLinkHover: Colors.black,
    seeAllLink: Colors.black,
    seeAllLinkHover: Colors.white,
    awardsHeading: Colors.black,
  },
  BdrColors: {
    awardsSection: Colors.black,
    awardsList: Colors.black,
    seeAllLink: Colors.black,
  },
  FontSizes: {
    awardsHeading: base_font * 1.375 + 'rem',
    brandLink: base_font * 1.25 + 'rem',
  },
  FontWeight: {
    medium: 500,
  },
};

export default CompanyAwardsStyle;
