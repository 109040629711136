import { Colors } from '@styles/graphite/styled';

const MapStyle = {
  Colors: {
    iconColor: Colors.black,
  },
  BgColors: {
    mapWrapper: Colors.gray12,
    mapProvince: Colors.blue1,
    hoverProvince: Colors.azure,
    iconBg: Colors.white,
    iconHoverBg: Colors.gray2,
  },
  BdrColor: {
    iconBorder: Colors.black,
  },
};

export default MapStyle;
