import { base_font, Colors, FontFamily, fontWeight } from '@styles/base/styled';

const JobAction = {
  Colors: {
    jobsHeading: Colors.gray1,
    badgeWrapper: Colors.black1,
  },
  BgColors: {
    contentBoxWrapper: Colors.gray2,
    jobsHeading: Colors.black1,
    jobListWrapper: Colors.white,
    badgeWrapper: Colors.gray1,
  },
  FontSizes: {
    title: (base_font * 0.85).toFixed(1) + 'rem',
  },
  FontWeight: {
    medium: 500,
    title: fontWeight.semibold,
    jobAge: fontWeight.semibold,
    normal: 400,
    bold: 700,
    accoladeName: 500,
  },
  FontFamily: {
    titleWrap: FontFamily.fontFamilyRaleway,
  },
  BdrColors: {
    contentBoxWrapper: Colors.shadowyBlack3,
    jobListWrapper: Colors.shadowyBlack3,
    jobList: Colors.shadowyBlack3,
    seeAllLink: Colors.gray1,
    arrowIconIsTrue: Colors.white,
    arrowIconIsFalse: Colors.blue22,
  },
};

export default JobAction;
