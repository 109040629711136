import { Colors, FontFamily, FontSizes, Icons } from '@styles/base/styled';

const SavedSearches = {
  Colors: {
    searchcolordark: Colors.black,
    searchtimecolor: Colors.gray1,
    notifyiconcolor: Colors.gray2,
    gridshadow: Colors.gray2,
    SuccessMsgWrap: Colors.black,
  },
  BdrColors: {
    jobsrchbotborder: Colors.black,
    jobsrchtopborder: Colors.gray1,
    searchcardborder: Colors.gray2,
    widgetcardborder: Colors.gray1,
    SuccessMsgWrap: Colors.black,
  },
  FontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
  },
  FontSizes: {
    clocktimefont: FontSizes.h5,
  },
  FontFamilies: {
    iconfonts: FontFamily.fontAwesome,
  },
  Icons: {
    clocklefticon: Icons.clockRotateLeft,
    tickicon: Icons.tickicon,
    circlecross: Icons.circlecross,
  },
  BgColors: {
    SuccessMsgWrap: Colors.gray1,
  },
};
export default SavedSearches;
