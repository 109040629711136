import { Colors, base_font } from '@styles/base/styled';

const BreadcrumbStyle = {
  Colors: {
    dropdownHeader: Colors.gray1,
    titleColor: Colors.black,
    BackBtn:Colors.black,
  },
  BgColors: {
    categoriesDropdown: Colors.white,
    breadcrumbWrapBg: Colors.white,
  },
  BdrColors: {
    categoriesDropdown: Colors.shadowyBlack,
    tileLinkColor: Colors.gray2,
    BackBtn:Colors.black,
  },
  FontSizes: {
    breadcrumb: (base_font * 0.875).toFixed(1) + 'rem',
    dropdownHeader: (base_font * 0.875).toFixed(1) + 'rem',
  },
};

export default BreadcrumbStyle;
