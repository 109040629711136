import { Colors } from '@styles/graphite/styled';

const ResearchCompanyStyle = {
  Colors: {
    title: Colors.blue1,
  },
  BgColors: {
    wrapper: Colors.blue4,
  },
};

export default ResearchCompanyStyle;
